import { ActionTransaction } from "Actions";
import { NotificationMessage } from "Constants/Utils";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import moment from "moment";
import { formatPrice } from "Helpers";

const WidgetListTransaction = (props) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getListData();
    }, [props.reload]);

    const getListData = async () => {
        setLoading(true);
        try {
            let params = {
                limit: 3,
                page: 1
            };
            await props.getListData(params);
        } catch (err) {
            NotificationMessage({ type: "warning", response: err });
        }
        setLoading(false);
    };

    return <Table
        dataSource={props.Transaction.listData.slice(0, 3)}
        columns={[
            {
                title: "Mã giao dịch",
                dataIndex: "transaction_code",
            },
            {
                title: "Gói cước",
                dataIndex: "extra_data",
                width: "300px",
                render: (item) => {
                    return item && item.package ? <React.Fragment>
                        <p className="btn btn-primary">{item.package.name}</p>
                        <p>Thời hạn: {item.package.time} ngày</p>
                    </React.Fragment> : null
                }
            },
            {
                title: "Giá",
                dataIndex: "amount",
                render: (item) => formatPrice(item) + ' đ'
            },
            {
                title: "Trạng thái",
                dataIndex: "status",
                render: (item) => {
                    return <span className={`text-${props.Config.statusTransaction[item].color}`}>{props.Config.statusTransaction[item].label}</span>
                }
            },
            {
                title: "Ghi chú",
                dataIndex: "note",
                render: (item) => {
                    return <Input.TextArea disabled style={{ minHeight: "100px", width: "200px" }} value={item}/>
                }
            },
            {
                title: "Thanh toán",
                dataIndex: "payment_time",
                render: (item) => item ? moment(item).format("HH:mm DD/MM/YYYY") : null
            },
            {
                title: "Thời gian",
                dataIndex: "created_time",
                align: "right",
                render: (item) => moment(item).format("HH:mm DD/MM/YYYY")
            },
        ]}
        rowKey="_id"
        loading={loading}
        pagination={false}
        scroll={{
            x: true
        }}
    />
}

const mapStateToProp = (state) => ({
    Transaction: state.Transaction,
    Config: state.Config
});

const mapDispatchToProps = (dispatch) => ({
    getListData: (value) => dispatch(ActionTransaction.list(value)),
});

export default connect(mapStateToProp, mapDispatchToProps)(WidgetListTransaction)