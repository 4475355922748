import { Table } from "antd";
import React from "react";
import { connect } from "react-redux";

const WidgetInfoLevel = (props) => {
    return <React.Fragment>
        <div className="card shadow-none" style={{ height: "100%"}}>
            <div className="card-header d-flex justify-content-between">
                <h5>Chính sách Affiliate </h5>
            </div>
            <div className="card-body">
                <Table 
                    dataSource={props.Setting.affiliate.levels}
                    columns={[
                        {
                            title: "Level",
                            dataIndex: "level"
                        },
                        {
                            title: "",
                            render: (value, record, index) => {
                                return <ul>
                                    <li>{record.number_user_min}-{record.number_user_max} khách hàng.</li>
                                    <li>{record.commission}% khách mới.</li>
                                    <li>{record.commission_extension}% trọn đời cho mỗi đơn hàng gia hạn.</li>
                                </ul>
                            }
                        }
                    ]}
                    pagination={false}
                />
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProp = (state) => ({
    Setting: state.Access.Setting
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProp, mapDispatchToProps)(WidgetInfoLevel)