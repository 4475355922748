import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { ActionRoot } from "Actions";
import { Button, Space, Table, Modal, Input, Tag, Select, Form } from 'antd';
import 'antd/dist/antd.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NotificationMessage } from 'Constants/Utils';

const { Option } = Select;

const Tool = (props) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { listTools, list, postList, messages, config } = props;
  const [update, setUpdate] = useState(false);
  const [pagination, setpagination] = useState({
    page: 1,
    limit: 20,
  });

  console.log(listTools);
  const [data, setData] = useState({
    name: "",
    status: "",
    function: []
  })
  const [form] = Form.useForm()
  const formFiler = useRef();
  const timeOutFilter = useRef();

  // Functions
  const showModal = (e) => {
    // debugger
    if (e !== 'Btn') {
      // debugger
      setIsModalVisible(true);
      const itemFilter = listTools.filter((item, index) => index === e)
      setData(itemFilter[0])
      form.setFieldsValue({
        name: itemFilter[0].name,
        status: itemFilter[0].status,
        function: itemFilter[0].function
      })
      setUpdate(true)
    } else if (e === 'Btn') {
      setIsModalVisible(true);
      setUpdate(false)
      if (data.label !== "" && data.value !== "") {
        setData({
          name: "",
          status: "",
          function: []
        })
        form.setFieldsValue({
          name: "",
          status: "",
          function: []
        })
      }
    }
  };

  const submitForm = (e) => {
    // debugger
    const emptyData = {
      name: "",
      status: "",
      function: []
    }
    if (data.label !== "" && data.value !== "" && update === false) {
      // debugger
      postList(data)
      list()
      setData(emptyData)
      setUpdate(false)
      NotificationMessage({ type: messages, description: "Thao tác thành công" });
      setIsModalVisible(false);
    }
    else
      if (data.label !== "" && data.value !== "" && update === true) {
        // debugger
        postList(data)
        list()
        setData(emptyData)
        NotificationMessage({ type: messages, description: "Thao tác thành công" });
        setIsModalVisible(false);
      }
  };

  const handleCancel = () => {
    setUpdate(false)
    setIsModalVisible(false);
  };

  const handleOnChangeInput = (e) => {
    const newData = { ...data }
    newData[e.target.id] = e.target.value
    setData(newData)
    console.log(data);
  }

  const handleChange = (value, label, index) => {
    // debugger
    if (value === 1 || value === 2) {
      data.status = value
      console.log(value);
    } else {
      const valueFilter = label.map((item) => { return { value: item.value, label: item.children } })
      data.function = valueFilter
      console.log(valueFilter);
    }
    console.log(data);
  };



  useEffect(() => {
    getListData();
  }, [pagination]);

  const getListData = async () => {
    // debugger
    setLoading(true);
    try {
      let params = { ...pagination };

      if (formFiler.current) params = { ...params, ...formFiler.current.getFieldsValue() }

      await props.list(params);
    } catch (err) {
      NotificationMessage({ type: "warning", response: err });
    }
    setLoading(false);
  };

  const filterData = (value) => {
    // debugger
    let timeout = 0;

    if (value.name) {
      timeout = 300;
    }

    if (timeOutFilter.current) clearTimeout(timeOutFilter.current);

    timeOutFilter.current = setTimeout(() => {
      getListData();
    }, timeout);
  }

  useEffect(() => {
    list()
  }, [])

  return (
    <div className="main-card card shadow-none">
      <div className="card-header d-flex justify-content-between">
        <h5>Danh sách phần mềm: {listTools && listTools.length}</h5>
      </div>
      <div className="card-body">
        <div>
          <Button type="primary" onClick={() => showModal('Btn')}>
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faPlus} />
            Thêm mới
          </Button>
          <div className="mt-3">
            <Form
              ref={formFiler}
              onValuesChange={filterData}
              onReset={getListData}
            >
              <div className="row">
                <div className="col-md-3">
                  <Form.Item name="name">
                    <Input placeholder={"Nhập tên phần mềm..."} />
                  </Form.Item>
                </div>
                <div className="col-md-2">
                  <Form.Item name="status">
                    <Select placeholder="Trạng thái" allowClear>
                      {
                        Object.keys(props.config.statusRoot).map((key) => {
                          let item = props.config.statusRoot[key];
                          return <Select.Option value={item.value}>{item.label}</Select.Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-auto">
                  <Form.Item>
                    <Button htmlType="reset">Làm mới</Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <Modal title={update === true ? `Sửa phần mềm ${data.name}` : "Thêm phần mềm mới"}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}>
          <Form
            form={form}
            name="basic"
            onFinish={submitForm}
            layout="vertical"
            initialValues={{
              status: data.status,
              name: data.name,
              function: data.function
            }}
          >
            <Form.Item label="Nhập tên phần mềm" name="name">
              <Input disabled={update === true} type="text" name="name" id="name" value={data.name} onChange={(e) => handleOnChangeInput(e)} placeholder='Nhập tên phần mềm' />
            </Form.Item>
            <Form.Item label="Nhập trạng thái" name="status">
              <Select
                style={{
                  width: '100%',
                }}
                onChange={handleChange}
                placeholder="Nhập trạng thái"

              >
                {
                  Object.keys(props.config.statusRoot).map((key) => {
                    let item = props.config.statusRoot[key];
                    return <Select.Option value={item.value}>{item.label}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item label="Nhập các chức năng" name="function">
              <Select
                mode="multiple"
                style={{
                  width: '100%',
                }}
                placeholder="Chọn các chức năng"
                onChange={handleChange}
                optionLabelProp="label"
              >
                {
                  Object.keys(props.config.listFunctionPackage).map((key) => {
                    let item = props.config.listFunctionPackage[key];
                    return <Select.Option value={item.value} label={item.label}>{item.label}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" loading={loading}>Xác nhận</Button>
            </Form.Item>
          </Form>
        </Modal>


        <Table

          dataSource={listTools}
          columns={[
            {
              title: 'STT',
              dataIndex: "1",
              key: 'ID',
              render: (text, item, index) => <a>{index + 1}</a>,
            },
            {
              title: 'Tên phần mềm',
              dataIndex: 'name',
              key: 'name',
              render: (text) => <a>{text}</a>,
            },
            {
              title: 'Trạng thái',
              dataIndex: 'status',
              key: 'status',
              render: (value) => {
                return props.config.statusUser[value] && <React.Fragment>
                  <span className={`text-${props.config.statusRoot[value].color}`}>{props.config.statusRoot[value].label}</span>
                </React.Fragment>
              }
            },
            {
              title: 'Chức năng',
              dataIndex: 'function',
              key: 'function',
              render: (_, { text }) => (
                <>
                  {
                    _ &&
                    _.map &&
                    _.map((text, index) => {
                      let color = text.length > 5 ? 'green' : 'geekblue';

                      if (index === 0 || index === 2 || index === 4 || index === 6 || index === 8) {
                        color = 'green';
                      }

                      return (
                        <Tag style={{ marginTop: '7px' }} color={color} key={text.value}>
                          {text.label.toUpperCase()}
                        </Tag>
                      );
                    })
                  }
                </>
              ),
            },
            {
              title: 'Hành động',
              key: 'action',
              render: (_, record, index) => (
                <Space size="middle">
                  <Button onClick={(e) => showModal(index)}><FontAwesomeIcon icon={faEdit} /></Button>
                </Space>
              ),
            },
          ]}

          rowKey="_id"
          loading={loading}
          pagination={{
            pageSize: pagination.limit,
            current: pagination.page,
            onChange: (page, pageSize) =>
              setpagination({ page: page, limit: pageSize }),
          }}
          scroll={{
            x: true
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProp = state => ({
  listTools: state.RootGetList.list,
  messages: state.RootGetList.messages,
  config: state.Config,
});

const mapDispatchToProps = dispatch => ({
  list: (value) => dispatch(ActionRoot.list(value)),
  postList: (value) => dispatch(ActionRoot.postList(value)),
});


export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Tool));