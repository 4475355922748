import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, message, Image, Table, Select } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ActionTransaction } from "Actions";
import { NotificationMessage } from "Constants/Utils";
import { formatPrice } from "Helpers";
import moment from "moment";

const Transaction = (props) => {
    const [loading, setLoading] = useState(false);
    const [pagination, setpagination] = useState({
        page: 1,
        limit: 20,
    });

    const formFiler = useRef();
    const timeOutFilter = useRef();

    useEffect(() => {
        getListData();
    }, [pagination]);

    const getListData = async () => {
        setLoading(true);
        try {
            let params = { ...pagination };

            if(formFiler.current) params = {...params, ...formFiler.current.getFieldsValue()}

            await props.getListData(params);
        } catch (err) {
            NotificationMessage({ type: "warning", response: err });
        }
        setLoading(false);
    };

    const filterData = (value) => {
        let timeout = 0;

        if (value.keyword) {
            timeout = 300;
        }

        if (timeOutFilter.current) clearTimeout(timeOutFilter.current);

        timeOutFilter.current = setTimeout(() => {
            getListData();
        }, timeout);
    }

    //main return
    return (
        <div className="main-card card shadow-none">
            <div className="card-header d-flex justify-content-between">
                <h5>Danh sách: {props.total}</h5>
            </div>
            <div className="card-body">
                <div className="">
                    <Form
                        ref={formFiler}
                        onValuesChange={filterData}
                        onReset={getListData}
                    >
                        <div className="row">
                            <div className="col-md-3">
                                <Form.Item name="keyword">
                                    <Input placeholder={"Nhập mã giao dịch hoặc tên gói cước.."} />
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item name="status">
                                    <Select placeholder="Trạng thái" allowClear>
                                        {
                                            Object.keys(props.config.statusTransaction).map((key) => {
                                                let item = props.config.statusTransaction[key];
                                                return <Select.Option value={key}>{item.label}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-auto">
                                <Form.Item>
                                    <Button htmlType="reset">Làm mới</Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
                <Table
                    dataSource={props.listData}
                    columns={[
                        {
                            title: "Mã giao dịch",
                            dataIndex: "transaction_code",
                        },
                        {
                            title: "Gói cước",
                            dataIndex: "extra_data",
                            width: "300px",
                            render: (item) => {
                                return item && item.package ? <React.Fragment>
                                    <p className="btn btn-primary">{item.package.name}</p>
                                    <p>Thời hạn: {item.package.time} ngày</p>
                                </React.Fragment> : null
                            }
                        },
                        {
                            title: "Giá",
                            dataIndex: "amount",
                            render: (item) => formatPrice(item)+' đ'
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "status",
                            render: (item) => {
                                return  <span className={`text-${props.config.statusTransaction[item].color}`}>{props.config.statusTransaction[item].label}</span>
                            }
                        },
                        {
                            title: "Ghi chú",
                            dataIndex: "note",
                            render: (item) => {
                                return <Input.TextArea disabled style={{minHeight: "100px", width: "200px"}} value={item}/>
                            }
                        },
                        {
                            title: "Thanh toán",
                            dataIndex: "payment_time",
                            render: (item) => item ? moment(item).format("HH:mm DD/MM/YYYY") : null
                        },
                        {
                            title: "Thời gian",
                            dataIndex: "created_time",
                            align: "right",
                            render: (item) => moment(item).format("HH:mm DD/MM/YYYY")
                        },
                    ]}
                    rowKey="_id"
                    loading={loading}
                    pagination={{
                        total: props.total,
                        pageSize: pagination.limit,
                        current: pagination.page,
                        onChange: (page, pageSize) =>
                            setpagination({ page: page, limit: pageSize }),
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProp = (state) => ({
    listData: state.Transaction.listData,
    total: state.Transaction.total,
    config: state.Config
});

const mapDispatchToProps = (dispatch) => ({
    getListData: (value) => dispatch(ActionTransaction.list(value)),
});

export default withRouter(
    connect(mapStateToProp, mapDispatchToProps)(Transaction)
);
