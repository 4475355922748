import React, { Component, useState, useEffect} from 'react';
import { Form, Input, Spin, Button, Alert} from 'antd';
import {withRouter, Link} from 'react-router-dom';

import { useDispatch, useSelector, connect } from 'react-redux';

import { logOut } from "../../Actions/Access";

const LogoutPage = (props) => {

    useEffect(() => {
        props.logOut()
    }, [])

    return <React.Fragment>
        <div className="text-center text-white" style={{fontSize: "18px"}}>LogOut...</div>
    </React.Fragment>
}


const mapStateToProp = state => ({

});

const mapDispatchToProps = dispatch => ({
    logOut: value => dispatch(logOut(value)),
});


export default withRouter(connect(mapStateToProp, mapDispatchToProps)(LogoutPage));