import React, { Component, useState, useEffect} from 'react';
import { Form, Input, Spin, Button, Alert} from 'antd';
import {withRouter, Link} from 'react-router-dom';

import { useDispatch, useSelector, connect } from 'react-redux';

import { postLogin } from "Actions/Access";
import { RedirectWithComponent, getCookie, setCookie, IsAuthen, NotificationMessage, SetAuthCookie } from "Constants/Utils";
import { AuthCookieName } from "Constants/ConstantsValue";
import Router from "Resources/Router.json";
import AppLoadingScreen from 'Layout/AppLoading';
import { ActionClientRequest, ActionUser } from 'Actions';

const LoginPage = (props) => {

    const submitLogin = async (values) => {
        try{
            let params = new URLSearchParams(props.location.search);
            let token = params.get("auth");
            let user = await props.checkLogin({
                auth_token: token
            });

            SetAuthCookie(token);

            if(params.get("url_redirect")){
                window.location.href = params.get("url_redirect");
            } else{
                RedirectWithComponent(`${Router.Home}`);
            }

        } catch(err){
            NotificationMessage({
                type: "warning",
                description: `${err.message}. Chuyển hướng sau 3s..`
            })
        }
    }

    useEffect(() => {
        submitLogin();
    }, [])

    return <React.Fragment>
        <AppLoadingScreen />
    </React.Fragment>
}


const mapStateToProp = state => ({
    UserLogin: state.Access.UserLogin
});

const mapDispatchToProps = dispatch => ({
    checkLogin: value => dispatch(ActionUser.detail(value)),
});


export default withRouter(connect(mapStateToProp, mapDispatchToProps)(LoginPage));