import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, message, Image, Table, Select, Tabs, InputNumber, Checkbox } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ActionAdminSetting, ActionAdminTransaction, ActionAdminUser, ActionTransaction } from "Actions";
import { NotificationMessage } from "Constants/Utils";
import { formatPrice } from "Helpers";
import moment from "moment";

import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const User = (props) => {
    const [loading, setLoading] = useState(false);
    const [listPackage, setlistPackage] = useState([]);
    const [listAffiliate, setlistAffiliate] = useState([]);
    const [tab, setTab] = useState(1);

    const formRef = useRef();
    const formAffiliate = useRef();

    useEffect(() => {
        getListData();
    }, []);
    

    useEffect(() => {
        if (props.AdminSetting.data) {
            changeTab(tab);
        }
    }, [props.AdminSetting.data, tab])

    const getListData = async () => {
        setLoading(true);
        try {
            await props.getListData();
        } catch (err) {
            NotificationMessage({ type: "warning", response: err });
        }
        setLoading(false);
    };

    const onSubmit = async (values) => {
        setLoading(true);
        try {
            values['banking'] = values['banking'].split("\n");
            await props.update(values);
            getListData();
            NotificationMessage({ type: "success", description: "Lưu cấu hình thành công" });
        } catch (err) {
            NotificationMessage({ type: "warning", response: err });
        }
        setLoading(false);
    }

    const addPackage = () => {
        if(listPackage.length >= 4) return;
        setlistPackage([
            ...listPackage,
            {
                "name": "Tên gói",
                "price": 1000,
                "time": 30,
                "description": "",
                "extra_data": {
                    "num_channel": -1,
                    "roles": []
                },
                "discount": 0,
            }
        ])
    }

    const removePackage = (index) => {
        listPackage.splice(index, 1);
        setlistPackage([...listPackage])
    }

    const changeValuePackage = (index, field, value) => {
        listPackage[index][field] = value;
        setlistPackage([...listPackage]);
    }

    const savePackage = async () => {
        setLoading(true);
        try {
            await props.update({
                list_packages: listPackage
            });
            getListData();
            NotificationMessage({ type: "success", description: "Lưu cấu hình thành công" });
        } catch (err) {
            NotificationMessage({ type: "warning", response: err });
        }
        setLoading(false);
    }

    const addValueAffiliate = () => {
        setlistAffiliate([
            ...listAffiliate,
            {
               level: "level",
               commission: 0,
               commission_extension: 0,
               number_user_max: 0,
               number_user_min: 0
            }
        ])
    }

    const changeValueAffiliate = (index, field, value) => {
        listAffiliate[index][field] = value;
        setlistAffiliate([...listAffiliate]);
    }

    const removeValueAffiliate = (index, field, value) => {
        listAffiliate.splice(index, 1);
        setlistAffiliate([...listAffiliate])
    }

    const saveAffiliate = async (values) => {
        setLoading(true);
        try {
            values['levels'] = listAffiliate;
            await props.update({
                affiliate: values
            });
            getListData();
            NotificationMessage({ type: "success", description: "Lưu cấu hình thành công" });
        } catch (err) {
            NotificationMessage({ type: "warning", response: err });
        }
        setLoading(false);
    }

    const changeTab = (tab) => {
        if(props.AdminSetting.data){
            if(tab == 1){
                setTimeout(() => {
                    if (formRef.current) formRef.current.setFieldsValue({
                        ...props.AdminSetting.data,
                        banking: props.AdminSetting.data.banking.join("\n")
                    });
                })
            } else if(tab == 2){
                setlistPackage([...JSON.parse(JSON.stringify(props.AdminSetting.data.list_packages))]);
            } else if(tab == 3){
                setTimeout(() => {
                    if(formAffiliate.current) formAffiliate.current.setFieldsValue({
                        ...props.AdminSetting.data.affiliate
                    })
                })

                setlistAffiliate([...JSON.parse(JSON.stringify(props.AdminSetting.data.affiliate.levels))])
            }
        }
    }

    //main return
    return (
        <div className="main-card card shadow-none">
            <div className="card-header d-flex justify-content-between">
                <h5>Cấu hình hệ thống</h5>
            </div>
            <div className="card-body">
                <Tabs defaultActiveKey="1" onChange={setTab}>
                    <Tabs.TabPane tab="Cấu hình chung" key="1" disabled={loading} >
                        <p className="mb-2"><strong>App Tool Id:</strong> {props.Setting.tool_id}</p>
                        <Form
                            layout="vertical"
                            ref={formRef}
                            onFinish={onSubmit}
                        >
                            <div className="row">
                                <Form.Item className="col-md-6" label="Link fanpage" name={"link_fanpage"}>
                                    <Input />
                                </Form.Item>
                                <Form.Item className="col-md-6" label="Link zalo" name={"link_zalo"}>
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="row">
                                <Form.Item className="col-md-6" label="Email" name={"email"}>
                                    <Input />
                                </Form.Item>
                                <Form.Item className="col-md-6" label="Alias mã giao dịch" name={"transaction_code"}>
                                    <Input />
                                </Form.Item>
                            </div>
                            <Form.Item label="Banking" name={"banking"}>
                                <Input.TextArea style={{ minHeight: "120px" }} />
                            </Form.Item>
                            <Button loading={loading} htmlType="submit" type="primary">Lưu cấu hình</Button>
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Gói cước" key="2" disabled={loading}>
                        <div className="bg-primary text-white p-2 pl-3 mb-3">
                            Lưu ý:
                            <p className="mb-0">- Số cửa hàng = -1 (Không giới hạn)</p>
                        </div>
                        <Table
                            dataSource={listPackage}
                            columns={[
                                {
                                    title: "Tên gói cước",
                                    dataIndex: "name",
                                    fixed: "left",
                                    width: "200px",
                                    render: (value, record, index) => {
                                        return <React.Fragment>
                                            <Input value={value} 
                                                onChange={(e) => changeValuePackage(index, "name", e.target.value )}
                                            />
                                        </React.Fragment>
                                    }
                                },
                                {
                                    title: "Giá",
                                    fixed: "left",
                                    width: "160px",
                                    dataIndex: "price",
                                    render: (value, record, index) => {
                                        return <InputNumber min={0} style={{ width: "160px" }} value={value} addonAfter="đ" 
                                        onChange={(e) => changeValuePackage(index, "price", e )}
                                        />
                                    }
                                },
                                {
                                    title: "Khuyến mãi",
                                    dataIndex: "discount",
                                    render: (value, record, index) => {
                                        return <InputNumber max={100} min={0} style={{ width: "100px" }} value={value} addonAfter="%" 
                                        onChange={(e) => changeValuePackage(index, "discount", e )}
                                        />
                                    }
                                },
                                {
                                    title: "Thời gian",
                                    dataIndex: "time",
                                    render: (value, record, index) => {
                                        return <InputNumber min={1} style={{ width: "120px" }} value={value} addonAfter="Ngày" 
                                            onChange={(e) => changeValuePackage(index, "time", e )}
                                        />
                                    }
                                },
                                {
                                    title: "Số cửa hàng",
                                    dataIndex: "extra_data",
                                    render: (value, record, index) => {
                                        return <InputNumber min={-1} style={{ width: "80px" }} value={value && value.num_channel} 
                                            onChange={(e) => {
                                                listPackage[index]['extra_data']['num_channel'] = e;
                                                setlistPackage([...listPackage]);
                                            }}
                                        />
                                    }
                                },
                                {
                                    title: "Chức năng",
                                    dataIndex: "extra_data",
                                    render: (value, record, index) => {
                                        return <Select mode={"multiple"} value={value && value.roles || []}
                                            allowClear={true}
                                            maxTagCount={1}
                                            style={{ width: "200px" }}
                                            placeholder="Chọn chức năng"
                                            showArrow
                                            onChange={(e) => {
                                                listPackage[index]['extra_data']['roles'] = e;
                                                setlistPackage([...listPackage]);
                                            }}
                                        >
                                            {
                                                props.Setting.app_tool.function.map((item) => {
                                                    return <Select.Option value={item.value}>{item.label}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    }
                                },
                                {
                                    title: "Hot",
                                    dataIndex: "is_hot",
                                    render: (value, record, index) => {
                                        return <Checkbox checked={value} onChange={(e) => changeValuePackage(index, "is_hot", e.target.checked ? 1 : 0 )} />
                                    }
                                },
                                {
                                    title: "Mô tả",
                                    dataIndex: "description",
                                    render: (value, record, index) => {
                                        return <Input.TextArea style={{ minHeight: "100px"}} value={value}
                                            onChange={(e) => changeValuePackage(index, "description", e.target.value)}
                                        />
                                    }
                                },
                                {
                                    title: "Hành động",
                                    align: "right",
                                    render: (value, record, index) => {
                                        return <React.Fragment>
                                            <Button size="small" danger onClick={() => removePackage(index)}><FontAwesomeIcon icon={faTrash} /></Button>
                                        </React.Fragment>
                                    }
                                }
                            ]}
                            pagination={false}
                            scroll={{
                                x: true
                            }}
                        />
                        <div className="mt-3">
                            <Button onClick={() => addPackage()}>Thêm mới</Button>
                            <Button type="primary" className="ml-2" onClick={() => savePackage()}>Lưu thay đổi</Button>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Affiliate" key="3" disabled={loading}>
                        <Form
                            layout="vertical"
                            onFinish={saveAffiliate}
                            ref={formAffiliate}
                        >
                            <div className="row">
                                <Form.Item className="col-2" label="Trạng thái" name="status">
                                    
                                    <Select placeholder="Chọn trạng thái">
                                        {
                                            Object.keys(props.Config.statusAffiliate).map((key) => {
                                                let item = props.Config.statusAffiliate[key];
                                                return <Select.Option value={parseInt(key)}>{item}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item className="col-3" label="Số tiền rút tối thiểu" name="min_withdraw">
                                    <InputNumber min={0} className="w-100" defaultValue={0}/>
                                </Form.Item>
                                <Form.Item className="col-7" label="Đường dẫn đăng ký affiliate" name="url" >
                                    <Input placeholder={`Vd: ${window.location.origin}`}/>
                                </Form.Item>
                                <Form.Item className="col-12" label="Level affiliate">
                                    <Table 
                                        dataSource={listAffiliate}
                                        columns={[
                                            {
                                                title: "Level",
                                                dataIndex: "level",
                                                render: (value, record, index) => {
                                                    return <Input value={value}
                                                        onChange={(e) => changeValueAffiliate(index, "level", e.target.value )}
                                                    />
                                                }
                                            },
                                            {
                                                title: "Số khách hàng giới thiệu",
                                                render: (value, record, index) => {
                                                    return <div className="d-flex align-items-center">
                                                        <InputNumber min={0} value={record.number_user_min}
                                                            onChange={(e) => changeValueAffiliate(index, "number_user_min", e )}
                                                        />
                                                        <span className="ml-2 mr-2">đến</span>
                                                        <InputNumber min={0} value={record.number_user_max}
                                                            onChange={(e) => changeValueAffiliate(index, "number_user_max", e )}
                                                        />
                                                    </div>
                                                }
                                            },
                                            {
                                                title: "Hoa hồng khách mới",
                                                dataIndex: "commission",
                                                width: "200px",
                                                render: (value, record, index) => {
                                                    return <InputNumber min={0} className="w-100" value={value}
                                                        onChange={(e) => changeValueAffiliate(index, "commission", e )}
                                                    />
                                                }
                                            },
                                            {
                                                title: "Hoa hồng khách gia hạn",
                                                dataIndex: "commission_extension",
                                                width: "200px",
                                                render: (value, record, index) => {
                                                    return <InputNumber min={0} className="w-100" value={value}
                                                        onChange={(e) => changeValueAffiliate(index, "commission_extension", e )}
                                                    />
                                                }
                                            },
                                            {
                                                title: "Hành động",
                                                align: "right",
                                                render: (value, record, index) => {
                                                    return <React.Fragment>
                                                        <Button size="small" danger onClick={() => removeValueAffiliate(index)}><FontAwesomeIcon icon={faTrash} /></Button>
                                                    </React.Fragment>
                                                }
                                            }
                                        ]}
                                        scroll={{
                                            x: true
                                        }}
                                        pagination={false}
                                    />
                                </Form.Item>
                                <Form.Item className="col-12">
                                    <Button onClick={() => addValueAffiliate()} className="mr-2">Thêm level</Button>
                                    <Button htmlType="submit" type="primary">Lưu cấu hình</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
};

const mapStateToProp = (state) => ({
    Config: state.Config,
    AdminSetting: state.AdminSetting,
    Setting: state.Access.Setting
});

const mapDispatchToProps = (dispatch) => ({
    getListData: (value) => dispatch(ActionAdminSetting.list(value)),
    update: (value) => dispatch(ActionAdminSetting.update(value))
});

export default withRouter(
    connect(mapStateToProp, mapDispatchToProps)(User)
);
