import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPrice } from "Helpers";
import React from "react";
import { connect } from "react-redux";


const WidgetPolicy = (props) => {
    return <React.Fragment>
        <div id="policy-affiliate" className="card shadow-none" style={{ height: "100%"}}>
            <div className="card-header d-flex justify-content-between">
                <h5>Điều kiện hợp lệ nhận hoa hồng Affiliate </h5>
            </div>
            <div className="card-body">
                <ul className="">
                    <li><FontAwesomeIcon icon={faCheck} /> Các Affiliater tuyệt đối tuân thủ luật quảng cáo online theo điều luật của nước Việt Nam.</li>
                    <li><FontAwesomeIcon icon={faCheck} /> Tuân thủ điều khoản đối tác của chúng tôi.</li>
                    <li><FontAwesomeIcon icon={faCheck} /> Tài khoản của bạn cần có ít nhất {formatPrice(props.Setting.affiliate.min_withdraw)}đ mới đủ điều kiện rút tiền.</li>
                    <li><FontAwesomeIcon icon={faCheck} /> Thành viên vi phạm sẽ bị hủy toàn bộ hoa hồng.</li>
                    <li><FontAwesomeIcon icon={faCheck} /> Tuân thủ đúng nghĩa vụ thuế và các nghĩa vụ pháp lý.</li>
                    <li><FontAwesomeIcon icon={faCheck} /> Liên hệ với fanpage khi đủ điều kiện rút hoặc có câu hỏi cần giải đáp.</li>
                </ul>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProp = (state) => ({
    Setting: state.Access.Setting
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProp, mapDispatchToProps)(WidgetPolicy)