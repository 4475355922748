import rootAction from "Actions/RootAction.json";

const initialState = {
    list: [],
    messages: "",
}

const GetListRootTool = (state = initialState, action) => {
    switch (action.type) {
        case rootAction.Root.Tools.ListTools:
            if (!action.data) return initialState;
            return {
                ...state,
                list: action.data.data,
                messages: "success"
            }
        case rootAction.Root.Tools.UpdateTools:
            if (!action.data) return initialState;
            return {
                ...state,
                messages: "success"
            }
        default:
            break;
    }
    return state;
}

export default GetListRootTool;