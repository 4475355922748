import { AuditLog } from "../../Constants/ConstantsValue";

export const SetAuditLog = (logType, messageKey, methodName, params) => {
    var log = {
        "Delay": 45000000,
        "Duration": null,
        "ExceptionInfo": null,
        "Identifier": "",
        "LogLevel": "INFO",
        "LogType": logType,
        "MessageKey": messageKey,
        "MethodName": methodName,
        "Parameter": params,
        "ProcessName": "Web Report",
        "Version": "1.0.0.0"
    };

    var logsave = localStorage.getItem(AuditLog);
    var logs = [];
    if (!logsave) {
        logs.push(log);
    }
    else {
        let buff = Buffer.from(localStorage.getItem(AuditLog), 'base64').toString();
        logs = JSON.parse(buff);
        logs.push(log);
    }
    localStorage.setItem(AuditLog, Buffer.from(JSON.stringify(logs)).toString('base64'));
};

export const ClearAuditLog = () => {
    localStorage.setItem(AuditLog, null);
};