import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, message, Image } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Router from "Resources/Router.json";
import moment from "moment";
import { ActionUser } from "Actions";
import { NotificationMessage } from "Constants/Utils";
import WidgetFormUpdate from "./Widget/FormChangePass";

const User = (props) => {
    const [loading, setLoading] = useState(false);
    const [visibleForm, setvisibleForm] = useState({
        visible: false
    })

    const formRef = useRef();

    useEffect(() => {
        formRef.current.setFieldsValue(props.UserLogin);
    }, []);

    const onSubmit = async (values) => {
        setLoading(true);
        try {
            await props.update(values);
            NotificationMessage({ type: "success", description: "Cập nhập tài khoản thành công!" });
        } catch (err) {
            NotificationMessage({ type: "warning", response: err });
        }
        setLoading(false);
    }

    const submitForm = () => {
        NotificationMessage({ type: "success", description: "Cập nhập tài khoản thành công!" });
    }

    //main return
    return (
        <div className="row">
            <WidgetFormUpdate
                {...visibleForm}
                onCancel={() => setvisibleForm({ visible: false })}
            />
            <div className="col-6">
                <div className="main-card card shadow-none">
                    <div className="card-header d-flex justify-content-between"><h5>Thông tin cá nhân</h5></div>
                    <div className="card-body">
                        <Form name="form" ref={formRef} size="large" layout="vertical" onFinish={onSubmit}>
                            <div className="text-center">
                                <img
                                    width="60px"
                                    src={props.UserLogin.avatar}
                                    onError={(e) => (e.target.src = "/logo.png")}
                                />
                            </div>
                            <Form.Item label={<strong>Họ và tên</strong>} name="fullname">
                                <Input />
                            </Form.Item>
                            <Form.Item label={<strong>Email</strong>} name="email">
                                <Input />
                            </Form.Item>
                            <Form.Item label={<strong>Số điện thoại</strong>} name="phone">
                                <Input />
                            </Form.Item>
                            <div className="d-flex">
                                <Form.Item className="text-right mb-0">
                                    <Button
                                        type="primary"
                                        size="middle"
                                        loading={loading}
                                        onClick={() => setvisibleForm({ visible: true })}
                                        className="mr-2"
                                    >
                                        Đổi mât khẩu
                                    </Button>
                                </Form.Item>
                                <Form.Item className="text-right mb-0">
                                    <Button htmlType="submit" type="primary" size="middle" loading={loading}>Cập nhập</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="main-card card shadow-none">
                    <div className="card-header d-flex justify-content-between"><h5>Gói cước đang sử dụng</h5></div>
                    <div className="card-body">
                        <div className="bg-light p-3 mb-3 mt-3">
                            {!props.UserLogin.package ? (
                                <React.Fragment>
                                    <p className="text-center mb-0">
                                        Bạn chưa có gói cước nào, vui lòng đăng ký để sử dụng các dịch
                                        vụ của chúng tôi!
                                    </p>
                                </React.Fragment>
                            ) : <React.Fragment>
                                <p className="mb-2">
                                    <strong className="border-bottom pb-1" style={{ fontSize: "16px" }}>{props.UserLogin.package.package.name}</strong>
                                </p>
                                {
                                    props.UserLogin.package.package.extra_data && props.UserLogin.package.package.extra_data.num_channel ?
                                        <p className="mb-1">
                                            <strong>Số cửa hàng:</strong> <span>
                                                {
                                                    props.UserLogin.package.package.extra_data.num_channel == -1 ? "Không giới hạn" : `${props.UserLogin.package.package.extra_data.num_channel}`
                                                }
                                            </span>
                                        </p> : null
                                }
                                <p className="mb-0"><strong>Chức năng:</strong></p>
                                <div className="pl-5 mb-1">
                                    {
                                        props.UserLogin.package.package.extra_data ?
                                            props.UserLogin.package.package.extra_data.roles.map((item) => {
                                                let find_role = props.Setting.app_tool.function.find((item_) => item_.value == item);
                                                return <p className="mb-0">{find_role ? find_role.label : item}</p>
                                            })
                                            : null
                                    }
                                </div>
                                <p className="mb-0"><strong>Ngày hết hạn:</strong> {moment(props.UserLogin.package.end_time).format("HH:mm DD/MM/YYYY")}</p>
                            </React.Fragment>}
                        </div>
                        <div className="text-center">
                            <Button
                                type="primary"
                                onClick={() => props.history.push(Router.Package.Index)}
                            >
                                Nâng cấp gói cước
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProp = (state) => ({
    UserLogin: state.Access.UserLogin,
    Setting: state.Access.Setting
});

const mapDispatchToProps = (dispatch) => ({
    update: value => dispatch(ActionUser.update(value))
});

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(User));
