import rootAction from "./RootAction.json";
import { CreateRequestOption } from "../Constants/Utils";
import { Api } from "../Constants/ConstantsValue";

export const logOut = () => {
    return {
        type: rootAction.Access.Logout,
    }
}

export const login = (value) => {
    return {
        type: rootAction.Access.Login,
        requestOption: CreateRequestOption("post", value),
        endPoint: Api.login,
    }
}

export const detail = (params) => {
    return {
        type: rootAction.Access.Detail,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.authDetail,
        params
    }
}

export const register = (value) => {
    return {
        type: rootAction.Access.Register,
        requestOption: CreateRequestOption("post", value),
        endPoint: Api.register
    }
}

export const update = (value) => {
    return {
        type: rootAction.User.Update,
        requestOption: CreateRequestOption("put", value),
        endPoint: Api.authUpdate
    }
}

export const changePass = (value) => {
    return {
        type: rootAction.User.CHANGEPASS,
        requestOption: CreateRequestOption("post", value),
        endPoint: Api.User.ChangePass,
    }
}

export default {
    register: register,
    detail: detail,
    login: login,
    logOut: logOut,
    update,
    changePass: changePass
}