import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { RedirectWithComponent, IsAuthen } from "../Constants/Utils";

const LoginLayout = (props) => {
    useEffect(() => {
        if (IsAuthen()) {
            RedirectWithComponent("/");
        }
    }, [])

    return (
        <div className="app-container">
            <div className="h-100 bg-white bg-animation">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <div className="mx-auto app-login-box col-md-8">
                        <div className="app-logo-text mx-auto mb-3 text-center text-dark">
                            {window.location.hostname}
                        </div>
                        {props.children}
                        <div className="text-center opacity-8 mt-3">Copyright © Lalasoft 2022</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(LoginLayout);
