import React, { Fragment } from 'react';
import { connect } from "react-redux";

import {
    DropdownToggle, DropdownMenu,
    Nav, Button, NavItem, NavLink,
    UncontrolledTooltip, UncontrolledButtonDropdown, DropdownItem
} from 'reactstrap';

import {
    toast,
    Bounce
} from 'react-toastify';


import {
    faCalendarAlt,
    faAngleDown,
    faBell,
    faGlobe

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import avatar1 from '../../../Assets/utils/images/user.jpg';

import ComponentNotification from './Notification';

import { withTranslation } from "react-i18next";

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };

    }

    notify2 = () => this.toastId = toast("You don't have any new items in your calendar for today! Go out and play!", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'success'
    });


    render() {
        return (
            <Fragment>
                {/* <div className="header-dots">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle color="link">
                            <div className="dropdown">
                                <button type="button" aria-haspopup="true" aria-expanded="true" className="p-0 mr-0 btn btn-link">
                                    <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                                        <div className="icon-wrapper-bg bg-danger"></div>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" color="#d92550" fontSize="23px" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M289.7 403c-6.1 0-11.4 4.2-12.7 10.2-1 4.5-2.7 8.2-5 10.9-1.3 1.5-5.1 5.9-16.1 5.9-11 0-14.8-4.5-16.1-5.9-2.3-2.7-4-6.4-5-10.9-1.3-6-6.6-10.2-12.7-10.2-8.4 0-14.5 7.8-12.7 15.9 5 22.3 21 37.1 46.5 37.1s41.5-14.7 46.5-37.1c1.8-8.1-4.4-15.9-12.7-15.9zM412 352.2c-15.4-20.3-45.7-32.2-45.7-123.1 0-93.3-41.2-130.8-79.6-139.8-3.6-.9-6.2-2.1-6.2-5.9v-2.9c0-13.3-10.8-24.6-24-24.6h-.6c-13.2 0-24 11.3-24 24.6v2.9c0 3.7-2.6 5-6.2 5.9-38.5 9.1-79.6 46.5-79.6 139.8 0 90.9-30.3 102.7-45.7 123.1-9.9 13.1-.5 31.8 15.9 31.8h280.1c16.1 0 25.4-18.8 15.6-31.8zm-39 5.8H139.8c-3.8 0-5.8-4.4-3.3-7.3 7-8 14.7-18.5 21-33.4 9.6-22.6 14.3-51.5 14.3-88.2 0-37.3 7-66.5 20.9-86.8 12.4-18.2 27.9-25.1 38.7-27.6 8.4-2 14.4-5.8 18.6-10.5 3.2-3.6 8.7-3.8 11.9-.2 5.1 5.7 12 9.1 18.8 10.7 10.8 2.5 26.3 9.4 38.7 27.6 13.9 20.3 20.9 49.5 20.9 86.8 0 36.7 4.7 65.6 14.3 88.2 6.5 15.2 14.4 25.9 21.5 33.9 2.2 2.7.4 6.8-3.1 6.8z"></path>
                                        </svg>
                                        <div className="badge badge-dot badge-dot-sm badge-danger">Notifications</div>
                                    </div>
                                </button>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu start right className="rm-pointers dropdown-menu-xl">
                            <ComponentNotification />
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div> */}
                <div className="header-dots">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle color="link">
                            <div className="dropdown">
                                <button type="button" aria-haspopup="true" aria-expanded="true" className="p-0 mr-0 btn btn-link">
                                    <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                                        <div className="icon-wrapper-bg bg-danger"></div>
                                        <FontAwesomeIcon icon={faGlobe} color={"#fff"} size={"lg"}/>
                                    </div>
                                </button>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu start right className="rm-pointers dropdown-menu-xs pt-0 pb-0">
                            <DropdownItem className="dropdown-menu-header-inner pt-4 pb-4 bg-focus" header>Choose Language</DropdownItem>
                            {
                                this.props.ThemeOptions.listLanguage.map((item) => {
                                    return <DropdownItem active={this.props.i18n.language == item.lng} className="pt-3 pb-3"
                                        onClick={(e) => {
                                            // this.props.i18n.changeLanguage(item.lng);
                                            localStorage.setItem("locale", item.lng);
                                            window.location.reload();
                                        }}
                                    >
                                        {item.label} <span className="ml-1 text-uppercase">({item.lng})</span>
                                    </DropdownItem>
                                })
                            }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
                <div className="header-btn-lg pr-0 ml-3">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="link" className="p-0">
                                        <img width={42} className="rounded-circle" src={this.props.UserLogin.avatar || avatar1} alt="" onError={(e) => e.target.src = avatar1} />
                                        {/* <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown} /> */}
                                    </DropdownToggle>
                                    {/* <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                        <Nav vertical>
                                            <NavItem className="nav-item-header">
                                                Activity
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">
                                                    Chat
                                                    <div className="ml-auto badge badge-pill badge-info">8</div>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">Recover Password</NavLink>
                                            </NavItem>
                                            <NavItem className="nav-item-header">
                                                My Account
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">
                                                    Settings
                                                    <div className="ml-auto badge badge-success">New</div>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">
                                                    Messages
                                                    <div className="ml-auto badge badge-warning">512</div>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">
                                                    Logs
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </DropdownMenu> */}
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className="widget-content-left  ml-3 header-user-info">
                                <div className="widget-heading">
                                    {this.props.UserLogin.fullname}
                                </div>
                                <div className="widget-subheading">
                                    User
                                </div>
                            </div>

                            {/* <div className="header-btn-lg">
                                
                            </div> */}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    ThemeOptions: state.ThemeOptions
})
const mapDispatchToProps = dispatch => ({});

export default withTranslation("common")(connect(mapStateToProps, mapDispatchToProps)(UserBox));