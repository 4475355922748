import rootAction from "Actions/RootAction.json";

const initialState = {
    user: {
        listData: [],
        total: 0
    },
    withdraws: {
        listData: [],
        total: 0
    }
}

const Affiliate = (state = initialState, action) => {
    switch (action.type) {
        case rootAction.Admin.Affiliate.List:
            if (!action.data) return initialState;
            return {
                ...state,
                user: {
                    total: action.data.total,
                    listData: action.data.data
                }
            }
        case rootAction.Admin.Affiliate.ListWithdraws:
            if (!action.data) return initialState;
            return {
                ...state,
                withdraws: {
                    total: action.data.total,
                    listData: action.data.data
                }
            }
        default:
            break;
    }
    return state;
}

export default Affiliate;