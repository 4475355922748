import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Modal, Select, Button} from "antd";
import { } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { ActionAdminTransaction, ActionUser } from "Actions";
import { NotificationMessage } from "Constants/Utils";

const ChangePassModal = (props) => {
    const { onCancel, visible, onFinish } = props;
    const [loading, setloading ] = useState(false);

    useEffect(() => {

    }, [visible])

    const submitForm = async (values) => {
        setloading(true);
        console.log(values);
        try{
            let res = await props.update({
                ...values,
            })
            NotificationMessage({
                type: "success",
                description: "Đổi mật khẩu thành công"
            })

            onCancel();

            if(onFinish) onFinish();

        } catch(err){
            NotificationMessage({
                type: "warning",
                response: err
            })
        }
        setloading(false);
    }

    return (
        <Modal
            visible={visible}
            title="Cập nhập mật khẩu"
            onCancel={onCancel}
            footer={null}
        >
            <Form
                name="basic"
                onFinish={submitForm}
                layout="vertical"
            >
                <Form.Item label="Nhập mật khẩu cũ" name="password_old">
                    <Input.Password style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item label="Nhập mật khẩu mới" name="password">
                    <Input.Password style={{width: "100%"}}/>
                </Form.Item>
                
                <Form.Item>
                    <Button 
                    htmlType="submit" 
                    type="primary" 
                    loading={loading}
                    
                    >Xác nhận</Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProp = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    update: (value) => dispatch(ActionUser.changePass(value)),
});

export default connect(mapStateToProp, mapDispatchToProps)(ChangePassModal);
