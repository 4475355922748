import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, message, Image, Table, Select } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ActionAdminAffiliate } from "Actions";
import { NotificationMessage } from "Constants/Utils";
import { formatPrice } from "Helpers";
import moment from "moment";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import WidgetFormUpdate from "./Widget/formUpdate";

const User = (props) => {
    const [loading, setLoading] = useState(false);
    const [pagination, setpagination] = useState({
        page: 1,
        limit: 20,
    });
    const [visibleForm, setvisibleForm] = useState({
        visible: false
    })

    const formFiler = useRef();
    const timeOutFilter = useRef();

    useEffect(() => {
        getListData();
    }, [pagination]);

    const getListData = async () => {
        setLoading(true);
        try {
            let params = { ...pagination };

            if(formFiler.current) params = {...params, ...formFiler.current.getFieldsValue()}

            await props.getListData(params);
        } catch (err) {
            NotificationMessage({ type: "warning", response: err });
        }
        setLoading(false);
    };

    const filterData = (value) => {
        let timeout = 0;

        if (value.keyword) {
            timeout = 300;
        }

        if (timeOutFilter.current) clearTimeout(timeOutFilter.current);

        timeOutFilter.current = setTimeout(() => {
            getListData();
        }, timeout);
    }

    //main return
    return (
        <div className="main-card card shadow-none">
            <WidgetFormUpdate 
                {...visibleForm}
                onCancel={() => setvisibleForm({visible: false })}
                onFinish={() => getListData() }
            />
            <div className="card-header d-flex justify-content-between">
                <h5>Danh sách: {props.total}</h5>
            </div>
            <div className="card-body">
                <div className="">
                    <Form
                        ref={formFiler}
                        onValuesChange={filterData}
                        onReset={getListData}
                    >
                        <div className="row">
                            {/* <div className="col-md-3">
                                <Form.Item name="keyword">
                                    <Input placeholder={"Nhập từ khóa tìm kiếm.."} />
                                </Form.Item>
                            </div> */}
                            <div className="col-md-2">
                                <Form.Item name="status">
                                    <Select placeholder="Trạng thái" allowClear>
                                        {
                                            Object.keys(props.Config.statusUserAffiliate).map((key) => {
                                                let item = props.Config.statusUserAffiliate[key];
                                                return <Select.Option value={key}>{item.label}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-auto">
                                <Form.Item>
                                    <Button htmlType="reset">Làm mới</Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
                <Table
                    dataSource={props.listData}
                    columns={[
                        {
                            title: "#",
                            render: (value, record, index) => {
                                return pagination.page > 1 ? ((pagination.limit * pagination.page) - pagination.limit) + index + 1 : index + 1
                            }
                        },
                        {
                            title: "Người dùng",
                            render: (item, record) => {
                                return <React.Fragment>
                                    <div className="d-flex align-items-center">
                                        <img src={record.user.avatar} width={"40px"} height="40px" className="round" 
                                            onError={(e) => e.target.src = props.themeOptions.avatarDefault }
                                        />
                                        <div className="ml-2">{record.user.fullname}</div>
                                    </div>
                                </React.Fragment>
                            }
                        },
                        {
                            title: "Thông tin đăng ký",
                            render: (item, record) => {
                                return <React.Fragment>
                                    <p className="mb-0">Email: {record.email}</p>
                                    <p className="mb-0">Sdt: {record.phone}</p>
                                    <p className="mb-0">Địa chỉ: {record.address}</p>
                                </React.Fragment>
                            }
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "status",
                            render: (value) => {
                                let status = props.Config.statusUserAffiliate[value];
                                return <span className={`text-${status.color}`}>{status.label}</span>
                            }
                        },
                        {
                            title: "Ví",
                            render: (value, record) => {
                                return <div>
                                    {formatPrice(record.wallet)+'đ'} / <span className="text-danger">{formatPrice(record.total_withdraws)+'đ'}</span>
                                </div>
                            }
                        },
                        {
                            title: "Người giới thiệu",
                            dataIndex: "number_user"
                        },
                        {
                            title: "Ngày đăng ký",
                            dataIndex: "created_time",
                            render: (item, record) => {
                                return moment(item).format("HH:mm DD/MM/YYYY")
                            }
                        },
                        {
                            title: "Hành động",
                            align: "right",
                            render: (value, record, index) => {
                                return <React.Fragment>
                                    {
                                        <Button size="small" 
                                            onClick={() => setvisibleForm({visible: true, item: record })}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                    }
                                </React.Fragment>
                            }
                        }
                    ]}
                    rowKey="_id"
                    loading={loading}
                    pagination={{
                        total: props.total,
                        pageSize: pagination.limit,
                        current: pagination.page,
                        onChange: (page, pageSize) =>
                            setpagination({ page: page, limit: pageSize }),
                    }}
                    scroll={{
                        x: true
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProp = (state) => ({
    listData: state.AdminAffiliate.user.listData,
    total: state.AdminAffiliate.user.total,
    Config: state.Config,
    themeOptions: state.ThemeOptions
});

const mapDispatchToProps = (dispatch) => ({
    getListData: (value) => dispatch(ActionAdminAffiliate.list(value))
});

export default withRouter(
    connect(mapStateToProp, mapDispatchToProps)(User)
);
