import rootAction from "Actions/RootAction.json";

const initialState = {
    statusTransaction: {
        0: {
            label: "Đang chờ",
            color: "warning"
        },
        1: {
            label: "Thành công",
            color: "success"
        },
        2: {
            label: "Thất bại",
            color: "danger"
        },
        3: {
            label: "Hủy đơn",
            color: "danger"
        }
    },
    statusUser: {
        1: {
            label: "Hoạt động",
            color: "success"
        },
        2: {
            label: "Tạm khóa",
            color: "danger"
        }
    },
    listRolesUser: ["admin"],
    listFunctionPackage: [
        {
            label: "Thông báo đơn hàng",
            value: "order_notify"
        },
        {
            label: "Thông báo tin nhắn",
            value: "message_notify"
        },
        {
            label: "Phân tích thị trường",
            value: "analysis"
        },
        {
            label: "Từ khóa hot",
            value: "hot_keyword"
        },
        {
            label: "SP bán chạy theo nghành",
            value: "top_product"
        },
        {
            label: "Đẩy sản phẩm (Thử nghiệm)",
            value: "push_product"
        },
        {
            label: "Sao chép/sửa sản phẩm (thử nghiệm)",
            value: "copy_product"
        },
        {
            label: "Công cụ đào từ khóa (Sắp có)",
            value: "search_keyword"
        },
        {
            label: "Doanh thu các ngành hàng theo ngày (Sắp có)",
            value: "revenue_cate"
        },
        {
            label: "Sản phẩm có lượt mua đột biến/bán chạy (Sắp có)",
            value: "top_sales_product"
        }
    ],
    statusAffiliate: {
        1: "Hiển thị",
        2: "Không hiển thị"
    },
    statusUserAffiliate: {
        0: {
            label: "Chờ xét duyệt",
            color: "warning"
        },
        1: {
            label: "Hoạt động",
            color: "success"
        },
        2: {
            label: "Tạm khóa",
            color: "danger"
        }
    },
    statusRoot: {
        1:{
            label: "Hoạt động",
            value: 1,
            color: "success"
        },
        2:{
            label: "Khóa",
            value: 2,
            color: "danger"
        }
    },
    extraDomain: {
        0: {
            label: "app.misell.co",
            value: "app.misell.co"
        },
        1: {
            label: "localhost",
            value: "localhost"
        },
        3: {
            label: "localhost:3000",
            value: "localhost:3000"
        },
        4: {
            label: "google.com",
            value: "google.com"
        }
    }
}

const Config = (state = initialState, action) => {
    switch (action.type) {
        default:
            break;
    }

    return initialState;
}

export default Config;