import rootAction from "Actions//RootAction.json";
import { CreateRequestOption } from "Constants/Utils";
import { Api } from "Constants/ConstantsValue";

export const list = (params) => {
    return {
        type: rootAction.Admin.Transaction.List,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Admin.Transaction.List,
        params
    }
}

export const update = (value) => {
    return {
        type: rootAction.Admin.Transaction.Update,
        requestOption: CreateRequestOption("post", value),
        endPoint: Api.Admin.Transaction.Update
    }
}

export default {
    list,
    update
}