// cookie value
export const AuthCookieName = "auth";

// localstorage value
export const AuditLog = "auditlog";

// access
export const AccessDenied = 403;

export const Api = {
  login: "/api/v1/user/login",
  register: "/api/v1/user/register",
  authDetail: "/api/v1/user/detail",
  authUpdate: "/api/v1/user/update",
  Transaction: {
    List: "/api/v1/transaction",
    RegisterPackage: "/api/v1/package"
  },
  Affiliate: {
    Detail: "/api/v1/affiliate",
    Register: "/api/v1/affiliate",
    ListTransaction: "/api/v1/affiliate/transaction",
    ListUser: "/api/v1/affiliate/user",
    ListWithdraws: "/api/v1/affiliate/withdraws",
    CreateWithdraws: "/api/v1/affiliate/withdraws"
  },
  Admin: {
    User: {
      List: "/api/v1/admin/user/list",
      Update: "/api/v1/admin/user"
    },
    Setting: {
      Get: "/api/v1/admin/service/setting",
      Update: "/api/v1/admin/service/setting"
    },
    Transaction: {
      List: "/api/v1/admin/transaction",
      Update: "/api/v1/admin/transaction" 
    },
    Affiliate: {
      List: "/api/v1/admin/affiliate",
      Update: "/api/v1/admin/affiliate",
      ListWithdraws: "/api/v1/admin/affiliate/withdraws",
      UpdateWithdraws: "/api/v1/admin/affiliate/withdraws"
    },
  },
  Root: {
    Login: "/api/v1/root/login",
    ToolList: "/api/v1/root/tool/list",
    ToolPost: "/api/v1/root/tool",
    ListDomain: "/api/v1/root/service/list",
    PostDomain: "/api/v1/root/service",
  },
  User: {
    ChangePass: "/api/v1/user/change_pw"
  }  
};