import XLSX from "xlsx";
import FileSaver from "file-saver";
import { NotificationMessage } from "Constants/Utils";

export function formatNumberK(num) {
  return Math.abs(num) > 999999
    ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "tr"
    : Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
}

export function formatPrice(price, format, decimal = true) {
  let final = new Intl.NumberFormat(format || "vi-VN").format(price);

  if (decimal) final = final.replace(/\./g, ",");

  return price ? final : 0;
}

export function formatNumberFixed(number, index) {
  return number ? parseFloat(number).toFixed(index || 1) : 0;
}

export function messageError(err) {
  return typeof err == "string"
    ? err
    : typeof err == "object" && err.message
      ? err.message
      : "An error occurred";
}

export function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export function marketShare(num1, num2) {
  return num2 > 0 ? parseFloat(((num1 / num2) * 100).toFixed(1)) : 0;
}

export function ExportToCSV({ domTable, fileName, listData }, _callback) {
  if (fileName == null || fileName == "") {
    throw { message: "Filename required!" };
  }

  let fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  let fileExtension = ".xlsx";

  let ws = null;

  if (domTable) {
    ws = XLSX.utils.table_to_sheet(domTable, {
      raw: true,
      type: "string",
    });
  } else if (listData) {
    ws = XLSX.utils.json_to_sheet(listData);
  }
  console.log(ws, listData);

  if (!ws) throw { message: "Export failed" };

  let wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  let excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  let data = new Blob([excelBuffer], { type: fileType });

  setTimeout(() => {
    FileSaver.saveAs(data, fileName + fileExtension);
    if (_callback) _callback();
  }, 1000);
}

export function FilterDataFomula({ data = [], filter = {} }) {
  let dataTemp = [];

  dataTemp = data.filter((item) => {
    let result = Object.keys((filter)).map((key) => {
      try {
        let value = item;

        key.split(".").forEach((key_) => {
          value = value[key_];
        })

        if (filter[key].fomula == "lt" && value < filter[key].value) return true;
        if (filter[key].fomula == "gt" && value > filter[key].value) return true;
        if (filter[key].fomula == "eq" && value == filter[key].value) return true;
        return false;
      } catch (err) {
        return false;
      }
    });

    return result.indexOf(false) > -1 ? false : true;
  });

  return dataTemp;
};

export function CopyText(text) {
  try{
    let copyText = document.querySelector('textarea#copy-text');

    if(!copyText){
      let node = document.createElement("textarea");
      node.id = "copy-text";
      document.querySelector("body").append(node);
      copyText = document.querySelector('textarea#copy-text');
    }

    copyText.textContent = text;
    
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    NotificationMessage({ type: "success", description: "Copy text thành công"});

  } catch(err){
    console.log(err);
    NotificationMessage({ type: "warning", response: err })
  }
}