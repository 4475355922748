const list_files = [
  "common",
  "message",
  "component",
];

const listLanguage = {
  en: {},
  vi: {},
};

list_files.forEach((item) => {
  Object.keys(listLanguage).forEach((key) => {
    try {
      listLanguage[key][item] = require(`Translations/${key}/${item}`);
    } catch (err) {
      listLanguage[key][item] = {};
    }
  });
});

export const resourcesLanguage = listLanguage;
