import rootAction from "Actions//RootAction.json";
import { CreateRequestOption } from "Constants/Utils";
import { Api } from "Constants/ConstantsValue";

export const list = (params) => {
    return {
        type: rootAction.Admin.Setting.Get,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Admin.Setting.Get,
        params
    }
}

export const update = (value) => {
    return {
        type: rootAction.Admin.Setting.Save,
        requestOption: CreateRequestOption("put", value),
        endPoint: Api.Admin.Setting.Update
    }
}

export default {
    list,
    update
}