import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, message, Image, Table, Select, Alert } from "antd";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ActionAffiliate, ActionTransaction } from "Actions";
import { NotificationMessage } from "Constants/Utils";

import WidgetRegister from "./Widget/register";
import WidgetInfoLevel from "./Widget/infoLevel";
import WidgetPolicy from "./Widget/policy";
import WidgetInfoAffiliate from "./Widget/infoAffiliate";
import WidgetListUser from "./Widget/listUser";

const Affiliate = (props) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getDetail();
    }, []);

    const getDetail = async () => {
        setLoading(true);
        try {
            await props.getDetail();
        } catch (err) {
            NotificationMessage({ type: "warning", response: err });
        }
        setLoading(false);
    };

    //main return
    return <React.Fragment>
        {
            !loading && (
                <React.Fragment>
                    {
                        !props.Affiliate.affiliate ? (
                            <React.Fragment>
                                <WidgetRegister />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {
                                    props.Affiliate.affiliate.status == 0 && <div className="card shadow-none mb-3">
                                        <div className="card-header">
                                            <h5>Tài khoản Affiliate</h5>
                                        </div>
                                        <div className="card-body">
                                            <Alert
                                                message="Thông báo"
                                                description={<p>Tài khoản affiliate của bạn đang chờ xét duyệt, vui lòng liên hệ tới chúng tôi nếu có bất cứ thắc mắc gì!</p>}
                                                type="info"
                                            />
                                        </div>
                                    </div>
                                }

                                {
                                    props.Affiliate.affiliate.status == 2 && <div className="card shadow-none mb-3">
                                        <div className="card-header">
                                            <h5>Tài khoản Affiliate</h5>
                                        </div>
                                        <div className="card-body">
                                            <Alert
                                                message="Thông báo"
                                                description={<p>Tài khoản affiliate của bạn tạm thời bị khóa, vui lòng liên hệ tới chúng tôi nếu có bất cứ thắc mắc gì!</p>}
                                                type="warning"
                                            />
                                        </div>
                                    </div>
                                }

                                {
                                    props.Affiliate.affiliate.status == 1 && < React.Fragment>
                                        <WidgetInfoAffiliate />
                                        <WidgetListUser />
                                    </React.Fragment>
                                }

                            </React.Fragment>
                        )
                    }
                </React.Fragment>
            )
        }
        <div className="row">
            <div className="col-md-6">
                <WidgetInfoLevel />
            </div>
            <div className="col-md-6">
                <WidgetPolicy />
            </div>
        </div>
    </React.Fragment>;
};

const mapStateToProp = (state) => ({
    Config: state.Config,
    Affiliate: state.Affiliate
});

const mapDispatchToProps = (dispatch) => ({
    getDetail: value => dispatch(ActionAffiliate.detail(value)),
    register: value => dispatch(ActionAffiliate.register(value))
});

export default withRouter(
    connect(mapStateToProp, mapDispatchToProps)(Affiliate)
);
