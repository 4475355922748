import { ActionAffiliate } from "Actions";
import { Table, Form, Select, Input, Button } from "antd";
import { NotificationMessage } from "Constants/Utils";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { formatPrice } from "Helpers";

const Withdraws = (props) => {
    const [loading, setloading] = useState(false);
    const [pagination, setpagination] = useState({
        page: 1,
        limit: 10
    })

    const formFiler = useRef();
    const timeOutFilter = useRef();

    useEffect(() => {
        getListData();
    }, [pagination])

    const getListData = async () => {
        setloading(true);
        try {
            let params = { ...pagination };
            if(formFiler.current) params = {...params, ...formFiler.current.getFieldsValue() };
            await props.getListData(params);
        } catch (err) {
            NotificationMessage({
                type: "warning",
                response: err
            })
        }
        setloading(false);
    }

    const filterData = (value) => {
        let timeout = 0;

        if (value.keyword) {
            timeout = 300;
        }

        if (timeOutFilter.current) clearTimeout(timeOutFilter.current);

        timeOutFilter.current = setTimeout(() => {
            getListData();
        }, timeout);
    }

    return <React.Fragment>
        <div className="card shadow-none">
            <div className="card-header">
                <h5>Lịch sử rút tiền: {props.Affiliate.withdraws.total} </h5>
            </div>
            <div className="card-body">
                <Form
                    ref={formFiler}
                    onValuesChange={filterData}
                    onReset={getListData}
                >
                    <div className="row">
                        <div className="col-md-2">
                            <Form.Item name="status">
                                <Select placeholder="Trạng thái" allowClear>
                                    {
                                        Object.keys(props.Config.statusTransaction).map((key) => {
                                            let item = props.Config.statusTransaction[key];
                                            return <Select.Option value={key}>{item.label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-auto">
                            <Form.Item>
                                <Button htmlType="reset">Làm mới</Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
                <Table
                    dataSource={props.Affiliate.withdraws.listData}
                    columns={[
                        {
                            title: "#",
                            render: (value, record, index) => {
                                return (pagination.limit * pagination.page) - pagination.limit + 1;
                            }
                        },
                        {
                            title: "Ngày rút",
                            dataIndex: "created_time",
                            render: (value) => moment(value).format("HH:mm DD/MM/YYYY")
                        },
                        {
                            title: "Thanh toán",
                            dataIndex: "payment_time",
                            render: (value) => value ? moment(value).format("HH:mm DD/MM/YYYY") : null
                        },
                        {
                            title: "Thông tin chuyển khoản",
                            dataIndex: "extra_data",
                            render: (value) => {
                                return value && <div style={{ whiteSpace: "pre-wrap" }}>
                                    {
                                        value.info_payment
                                    }
                                </div>
                            }
                        },
                        {
                            title: "Số tiền",
                            dataIndex: "amount",
                            render: (value) => {
                                return formatPrice(value) + 'đ'
                            }
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "status",
                            render: (value) => {
                                let status = props.Config.statusTransaction[value];
                                return <span className={`text-${status.color}`}>{status.label}</span>
                            }
                        },
                        {
                            title: "Ghi chú",
                            dataIndex: "note",
                            render: (value) => <Input.TextArea value={value} disabled style={{minHeight: "120px"}}/>
                        }
                    ]}
                    pagination={{
                        total: props.Affiliate.withdraws.total,
                        current: pagination.page,
                        pageSize: pagination.limit,
                        onChange: (page, pageSize) => {
                            setpagination({
                                page: page,
                                limit: pageSize
                            })
                        }
                    }}
                    loading={loading}
                />
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProp = (state) => ({
    Setting: state.Access.Setting,
    Affiliate: state.Affiliate,
    Config: state.Config
})

const mapDispatchToProps = (dispatch) => ({
    getListData: value => dispatch(ActionAffiliate.listWithdraws(value))
})

export default connect(mapStateToProp, mapDispatchToProps)(Withdraws)