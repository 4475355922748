import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { ActionNotification } from "../../../Actions";

import cx from "classnames";
import moment from "moment";

import { faClock } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PerfectScrollbar from 'react-perfect-scrollbar';

const Notification = (props) => {

    const { listData } = props;

    const pagination = useRef({ limit: 6, page: 0, next: true });
    const [ loading, setLoading ] = useState(false);

    const getListData = async () => {
        setLoading(true);
        setTimeout(async () => {
            try {
                if(pagination.current.next){
                    pagination.current.page += 1;
    
                    let params = {
                        limit: pagination.current.limit,
                        page: pagination.current.page
                    }
                    let response = await props.getListData(params);
        
                    if(response.data.data.length == 0){
                        pagination.current.next = false;
                    }
                }
            } catch (err) {
    
            }
            setLoading(false);
        }, 300)
    }

    useEffect(() => {
        getListData();
        return () => {
            pagination.current.page = 0;
            pagination.current.next = true;
            props.setState({
                listData: []
            })
        }
    }, [])

    return <React.Fragment>
        <div className="dropdown-menu-header mb-0">
            <div className="dropdown-menu-header-inner bg-deep-blue">
                <div className="menu-header-image opacity-1"></div>
                <div className="menu-header-content text-dark">
                    <h5 className="menu-header-title">Notifications</h5>
                    {/* <h6 className="menu-header-subtitle">You have <b>0</b> unread messages</h6> */}
                </div>
            </div>
        </div>
        {
            listData.length == 0 ? <div className="dropdown-content p-5">
                <h6 className="menu-header-subtitle text-center">You have <b>0</b> messages</h6>
            </div> : <div className="scroll-area-lg">
                <PerfectScrollbar onScrollDown={(e) => {
                    if(!loading && pagination.current.next && e.scrollTop == e.scrollHeight - e.clientHeight){
                        getListData();
                    }
                }}>
                    <div className="p-3">
                        {
                            listData.map((item, i) => {
                                return <div class={cx('widget-content p-0', i < listData.length - 1 ? 'border-bottom pb-2' : '', i != 0 ? 'pt-2' : '')}>
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            {/* <img width="42" class="rounded-circle" src="" alt="" /> */}
                                            <i className="metismenu-icon pe-7s-graph1" style={{ fontSize: "30px" }}></i>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">{item.title}</div>
                                            <div class="widget-subheading" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                            <p className="text-right widget-subheading mb-1 mt-2 d-flex align-items-center justify-content-end" style={{ fontSize: "13px" }}>
                                                <i className="metismenu-icon pe-7s-clock mr-1"></i> {moment(item.created_time).fromNow()}</p>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    {
                        loading ? 
                        <div className="text-center mb-3">loading...</div> : null
                    }
                </PerfectScrollbar>
            </div>
        }
        <ul class="nav flex-column">
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item-btn text-center nav-item"><button class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm">View Latest Changes</button></li>
        </ul>
    </React.Fragment>
}

const mapStateToProps = state => ({
    listData: state.Notification.listData,
});

const mapDispatchToProps = dispatch => ({
    getListData: value => dispatch(ActionNotification.list(value)),
    setState: value => dispatch(ActionNotification.setState(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);