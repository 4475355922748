import { ActionAffiliate } from "Actions";
import { Form, Modal, Table, Input, InputNumber, Button } from "antd";
import { NotificationMessage } from "Constants/Utils";
import { formatPrice } from "Helpers";
import React, { useState } from "react";
import { connect } from "react-redux";

const WidgetModalWithDraw = (props) => {
    const { visible, hide } = props;
    const min_withdraw = props.Setting.affiliate.min_withdraw;

    const [loading, setloading] = useState(false);
    const [amountWithDraws, setamountWithDraws] = useState(min_withdraw);

    const onFinish = async (values) => {
        setloading(true);
        try{
            await props.createWithdraws(values);
            NotificationMessage({ type: "success", description: "Thao tác thành công, vui lòng chờ xác nhận từ chúng tôi!" });
            hide();
        } catch(err){
            NotificationMessage({ type: "warning", response: err })
        }
        setloading(false);
    }

    return <Modal
        title={'Yêu cầu rút tiền'}
        visible={visible}
        onCancel={hide}
        footer={null}
    >
        <Form
            name="basic"
            onFinish={onFinish}
            initialValues={{
                amount: min_withdraw
            }}
            layout="vertical"
        >
            <Form.Item
                name="amount"
                rules={[{ required: true, message: 'Số tiền bắt buộc' }]}
                label={`Số tiền (${formatPrice(amountWithDraws)} vnđ)`}
                className="mb-5"
            >
                <InputNumber min={min_withdraw} style={{ width: "100%" }} onChange={(e) => {
                    setamountWithDraws(e)
                }}/>
            </Form.Item>
            <Form.Item
                name="info_payment"
                rules={[{ required: true, message: 'Nội dung chuyển khoản là bắt buộc' }]}
                label="Nội dung chuyển khoản"
            >
                <Input.TextArea style={{ minHeight: "100px" }} />
            </Form.Item>
            <div>
                Ví dụ nội dung chuyển khoản:
                <p className="mb-0 pl-20">Ngân hàng: Vietcombank - Chi nhánh: ..</p>
                <p className="mb-0 pl-20">Chủ TK: ..</p>
                <p className="mb-0 pl-20">Số tài khoản: ..</p>
            </div>
            <Form.Item className="mb-0 text-right">
                <Button className="mr-2" onClick={hide}>
                    Đóng
                </Button>
                <Button htmlType="submit" type="primary" loading={loading}>
                    Xác nhận
                </Button>
            </Form.Item>
        </Form>
    </Modal>
}

const mapStateToProp = (state) => ({
    Setting: state.Access.Setting
})

const mapDispatchToProps = (dispatch) => ({
    createWithdraws: value => dispatch(ActionAffiliate.createWithdraws(value))
})

export default connect(mapStateToProp, mapDispatchToProps)(WidgetModalWithDraw)