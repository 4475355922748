import rootAction from "Actions//RootAction.json";
import { CreateRequestOption } from "Constants/Utils";
import { Api } from "Constants/ConstantsValue";

export const list = (params) => {
    return {
        type: rootAction.Admin.User.List,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Admin.User.List,
        params
    }
}

export const update = (id, value) => {
    return {
        type: rootAction.Admin.User.Update,
        requestOption: CreateRequestOption("put", value),
        endPoint: Api.Admin.User.Update+'/'+id
    }
}

export default {
    list,
    update
}