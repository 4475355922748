import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "antd";
import { NotificationMessage } from "Constants/Utils";
import { CopyText, formatPrice } from "Helpers";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Router from "Resources/Router.json";

import WidgetModalWithDraw from "./modalWithdraw";

const WidgetInfoAffiliate = (props) => {
    const [visibleWithdraw, setvisibleWithdraw] = useState(false);

    const affiliate = props.Affiliate.affiliate;
    const link_ref = `${(props.Setting.affiliate && props.Setting.affiliate.url) || window.location.origin}?ref=${affiliate.user_id}`;
    
    const showModalWithdraw = () => {
        if(affiliate.wallet < props.Setting.affiliate.min_withdraw){
            NotificationMessage({
                type: "warning",
                description: `Tài khoản của bạn phải >= ${formatPrice(props.Setting.affiliate.min_withdraw)} vnđ`
            })
        } else{
            setvisibleWithdraw(true);
        }
    }

    return <React.Fragment>
        <WidgetModalWithDraw 
            visible={visibleWithdraw} 
            hide={() => setvisibleWithdraw(false)}
        />

        <div id="info-affiliate" className="row mb-3">
            <div className="col-4">
                <div className="card shadow-none" style={{ background: "#f29e4f", height: "100%" }}>
                    <div className="card-body d-flex flex-column justify-content-between">
                        <h4 className="text-white font-weight-bold">Tài khoản Affiliate level {affiliate.level.level}</h4>
                        <h3 className="text-white">{formatPrice(affiliate.wallet)} đ</h3>
                        <div className="d-flex">
                            <Button className="mr-2" type="primary" onClick={showModalWithdraw}>Rút tiền</Button>
                            <Link to={Router.Affiliate.ListWithdraws}>
                                <Button type="primary">Lịch sử rút</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-3">
                <div className="card shadow-none" style={{ background: "#4267b2", height: "100%" }}>
                    <div className="card-body text-center d-flex flex-column justify-content-between">
                        <h4 className="text-white font-weight-bold">Hoa hồng thanh toán lần đầu</h4>
                        <h3 className="text-white">{affiliate.level.commission}%</h3>
                        <p className="mb-0 text-white">Hoa hồng gia hạn {affiliate.level.commission_extension}%</p>
                    </div>
                </div>
            </div>

            <div className="col-5">
                <div className="card shadow-none" style={{ height: "100%" }}>
                    <div className="card-body d-flex flex-column justify-content-between">
                        <h4 className="mb-3 font-weight-bold">Link đăng ký</h4>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <Input disabled className="w-100" value={link_ref}/>
                            </div>
                            <div>
                                <Button type="primary" onClick={() => CopyText(link_ref)}>Sao chép</Button>
                            </div>
                        </div>
                        <ul>
                            <li className="pl-0"><FontAwesomeIcon icon={faCheck} /> <strong>Copy link này gửi cho những khách hàng khác và hướng dẫn họ đăng ký</strong></li>
                            <li className="pl-0"><FontAwesomeIcon icon={faCheck} /> <strong>Cookie 30 ngày và không bị ghi đè bởi link của người giới thiệu sau</strong></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProp = (state) => ({
    Setting: state.Access.Setting,
    Affiliate: state.Affiliate
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProp, mapDispatchToProps)(WidgetInfoAffiliate)