import rootAction from "Actions//RootAction.json";
import { CreateRequestOption } from "Constants/Utils";
import { Api } from "Constants/ConstantsValue";
import qs from "qs";

export const list = (params) => {
    return {
        type: rootAction.Transaction.List,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Transaction.List,
        params
    }
}

export default {
    list
}