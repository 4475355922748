import React, { Component, Fragment, useEffect } from "react";

import { connect } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import MetisMenu from "react-metismenu";
import cx from "classnames";
import { withTranslation, useTranslation } from "react-i18next";

import { MainNav, Admin } from "./NavItems";
import NavLink from "./NavLink";

import { useDispatch } from "react-redux";
import { logOut } from "../../Actions/Access";

const Nav = (props) => {
  const { Setting } = props;

  const dispatch = useDispatch();
  var menu = null;

  useEffect(() => {

  }, [])

  return (
    <Fragment>
      {
        MainNav.map((item) => {
          // debugger
          if(item.roles && item.roles.length > 0){
            let roles = props.UserLogin.roles.filter((role) => item.roles.indexOf(role) > -1);
            if(roles.length == 0) return;
          }else if (item.is_root && item.is_root.length > 0){
            let root = props.UserLogin.is_root === true ? props.UserLogin.is_root : false;
            if (root === false) return;
          }
          return <React.Fragment>
            {
              (props.UserLogin.is_root === true) ? item.title_root && <h5 className="app-sidebar__heading">{props.t(item.title_root)}</h5>  : item.title && <h5 className="app-sidebar__heading">{props.t(item.title)}</h5> 
            }
            <MetisMenu
              ref={(evt) => (menu = evt)}
              content={(props.UserLogin.is_root === true) ? item.menus_root : item.menus}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
              LinkComponent={NavLink}
            />
          </React.Fragment>
        })
      }

      <div className="metismenu vertical-nav-menu">
        <ul className="metismenu-container">
          <li className="metismenu-item">
            <a
              className="metismenu-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                dispatch(logOut());
              }}
            >
              <i className="metismenu-icon pe-7s-back"></i>
              { props.t("logout") }
            </a>
          </li>
        </ul>
      </div>

    </Fragment>
  );
};

const mapStateToProp = (state) => ({
  Setting: state.Access.Setting,
  UserLogin: state.Access.UserLogin,
});

export default withTranslation("common")(withRouter(connect(mapStateToProp)(Nav)));
