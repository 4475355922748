import rootAction from "../../Actions/RootAction.json";

const initialState = {
    total: 0,
    listData: []
}

const notification = (state = initialState, action) => {
    switch (action.type) {
        case rootAction.Notification.List:
            if (!action.data) return initialState;
            let data = state.listData;
            action.data.data.forEach((item) => {
                let find = data.find((item_) => item_._id == item._id);
                if (!find) data.push(item);
            })

            return {
                ...state,
                total: action.data.total,
                listData: [...data]
            }
        case rootAction.Notification.SetState:
            return {
                ...state,
                ...action.data
            }
            break;
        default:
            break;
    }
    return state;
}

export default notification;