import { beforeRequest, afterRequest } from "../Actions/ClientRequest";
import { logOut } from "../Actions/Access";

// Constants value
import { AccessDenied, NotLoginWithApp, AuthCookieName } from "../Constants/ConstantsValue";
import { IsAuthen, RedirectUrl, RedirectWithComponent, getCookie } from "Constants/Utils";

// Audit Log
import { ClearAuditLog } from "Helpers/Logs/AuditLog";

import Router from "Resources/Router.json";

import { message } from "antd";

const baseUrl = process.env.REACT_APP_API_SITE || window.location.origin;
const errorURL = `/${Router.Error}`;

// Middleware for actions
export const actionMiddleware = (store) => (next) => async (action) => {

    if(process.env.NODE_ENV == "development") console.log('action', action);
    
    if (action.endPoint && action.requestOption) {
        store.dispatch(beforeRequest(action.type, action.sendValues));
        try {
            let full_url = new URL(baseUrl + action.endPoint);

            if(action.params) {
                Object.keys(action.params).forEach((key) => {
                    if(action.params[key]) full_url.searchParams.set(key, action.params[key]);
                })
            }
            
            // Call api async
            const request = await fetch(full_url.href, {
                ...action.requestOption
            });
            
            if (action.contentType === "blob") {
                let blob = await request.blob();
                if (!blob) {
                    return systemError();
                }
                action.data = blob;
            } else {
                const response = await request.json();
                action.data = response;
            }

            switch (request.status) {
                case 200:
                    break;
                case 403:
                    store.dispatch(logOut(action.data));
                    // systemError(request.status, action.data.message);
                    throw(action.data);
                    break;
                case 401:
                    store.dispatch(logOut(action.data));
                    throw(action.data);
                    break;
                default:
                    if(action.messageError) message.error(action.data.message || "An error has occurred!");
                    throw(action.data);
                    break
            }
        }
        catch (err){
            throw(err);
        }
        finally {
            ClearAuditLog();
            store.dispatch(afterRequest(action.type, action.sendValues, action.data));
        }
    }

    return next(action);
}

// Error api case
const systemError = (status, message) => {
    let params = {
        ErrorId: Router.SubError.System,
        status: status,
        message: message
    }
    RedirectWithComponent(`/${Router.SubError.System}`, params);
}

const notLoginWithApp = () => {
    let params = {
        ErrorId: Router.SubError.NotLoginApp
    }
    RedirectWithComponent(errorURL, params);
}

const notAccess = () => {
    let params = {
        ErrorId: Router.SubError.NotAccess
    }
    RedirectWithComponent(errorURL, params);
}