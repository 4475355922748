import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, message, Image, Table, Select } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ActionAdminTransaction, ActionTransaction } from "Actions";
import { NotificationMessage } from "Constants/Utils";
import { formatPrice } from "Helpers";
import moment from "moment";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import WidgetFormUpdate from "./Widget/formUpdate";

const Transaction = (props) => {
    // debugger
    const [loading, setLoading] = useState(false);
    const [pagination, setpagination] = useState({
        page: 1,
        limit: 20,
    });
    const [visibleForm, setvisibleForm] = useState({
        visible: false
    })

    const formFiler = useRef();
    const timeOutFilter = useRef();

    useEffect(() => {
        getListData();
    }, [pagination]);

    const getListData = async () => {

        setLoading(true);
        try {
            let params = { ...pagination };

            if (formFiler.current) params = { ...params, ...formFiler.current.getFieldsValue() }

            await props.getListData(params);
        } catch (err) {
            NotificationMessage({ type: "warning", response: err });
        }
        setLoading(false);
    };

    const filterData = (value) => {

        let timeout = 0;

        if (value.keyword || value.username) {
            timeout = 300;
        }

        if (timeOutFilter.current) clearTimeout(timeOutFilter.current);

        timeOutFilter.current = setTimeout(() => {
            getListData();
        }, timeout);
    }

    //main return
    return (
        <div className="main-card card shadow-none">
            <WidgetFormUpdate
                {...visibleForm}
                onCancel={() => setvisibleForm({ visible: false })}
                onFinish={() => getListData()}
            />
            <div className="card-header d-flex justify-content-between">
                <h5>Danh sách: {props.total}</h5>
            </div>
            <div className="card-body">
                <div className="">
                    <Form
                        ref={formFiler}
                        onValuesChange={filterData}
                        onReset={getListData}
                    >
                        <div className="row">
                            <div className="col-md-3">
                                <Form.Item name="keyword">
                                    <Input placeholder={"Nhập mã giao dịch hoặc tên gói cước.."} />
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item name="username">
                                    <Input placeholder={"Username người dùng.."} />
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item name="status">
                                    <Select placeholder="Trạng thái" allowClear>
                                        {
                                            Object.keys(props.config.statusTransaction).map((key) => {
                                                let item = props.config.statusTransaction[key];
                                                return <Select.Option value={key}>{item.label}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-auto">
                                <Form.Item>
                                    <Button htmlType="reset">Làm mới</Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
                <Table
                    dataSource={props.listData}
                    columns={[
                        {
                            title: "Mã giao dịch",
                            dataIndex: "transaction_code",
                            width: 160,
                            fixed: "left"
                        },
                        {
                            title: "Gói cước",
                            dataIndex: "extra_data",
                            render: (item) => {
                                return item && item.package ? <React.Fragment>
                                    <p className="btn btn-primary">{item.package.name}</p>
                                    <p className="mb-0">Thời hạn: {item.package.time} ngày</p>
                                </React.Fragment> : null
                            }
                        },
                        {
                            title: "Giá",
                            dataIndex: "amount",
                            render: (item) => formatPrice(item) + ' đ'
                        },
                        {
                            title: "Người dùng",
                            dataIndex: "user",
                            render: (item) => {
                                return <React.Fragment>
                                    <div className="d-flex align-items-center">
                                        <img src={item.avatar} width={"40px"} height="40px" className="round"
                                            onError={(e) => e.target.src = props.themeOptions.avatarDefault}
                                        />
                                        <div className="ml-2">{item.fullname}</div>
                                    </div>
                                </React.Fragment>
                            }
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "status",
                            render: (item) => {
                                return <span className={`text-${props.config.statusTransaction[item].color}`}>{props.config.statusTransaction[item].label}</span>
                            }
                        },
                        {
                            title: "Ghi chú",
                            dataIndex: "note",
                            render: (item) => {
                                return <Input.TextArea style={{ minHeight: "100px" }} value={item} />
                            }
                        },
                        {
                            title: "Thời gian",
                            dataIndex: "created_time",
                            render: (item, record) => {
                                return <React.Fragment>
                                    <p>Ngày tạo: {moment(record.created_time).format("HH:mm DD/MM/YYYY")}</p>
                                    <p className="mb-0">Thanh toán: {record.payment_time ? moment(record.payment_time).format("HH:mm DD/MM/YYYY") : null}</p>
                                </React.Fragment>
                            }
                        },
                        {
                            title: "Hành động",
                            align: "right",
                            render: (value, record, index) => {
                                return <React.Fragment>
                                    {
                                        <Button size="small"
                                            disabled={record.status != 0}
                                            onClick={() => setvisibleForm({ visible: true, id: record._id })}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                    }
                                </React.Fragment>
                            }
                        }
                    ]}
                    rowKey="_id"
                    loading={loading}
                    pagination={{
                        total: props.total,
                        pageSize: pagination.limit,
                        current: pagination.page,
                        onChange: (page, pageSize) =>
                            setpagination({ page: page, limit: pageSize }),
                    }}
                    scroll={{
                        x: true
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProp = (state) => ({
    listData: state.AdminTransaction.listData,
    total: state.AdminTransaction.total,
    config: state.Config,
    themeOptions: state.ThemeOptions
});

const mapDispatchToProps = (dispatch) => ({
    getListData: (value) => dispatch(ActionAdminTransaction.list(value)),
    update: (value) => dispatch(ActionAdminTransaction.update(value)),
});

export default withRouter(
    connect(mapStateToProp, mapDispatchToProps)(Transaction)
);
