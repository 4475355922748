import React, { Component, useState, useEffect } from 'react';
import { Form, Input, Spin, Button, Alert, notification } from 'antd';
import { withRouter, Link } from 'react-router-dom';

import { useDispatch, useSelector, connect } from 'react-redux';

import { ActionUser } from "../../Actions";

import rootRouter from "../../Resources/Router.json";
import { getCookie, NotificationMessage } from 'Constants/Utils';

const RegisterPage = (props) => {
    var form = null;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {

    }, [])

    const submitForm = async (values) => {
        setLoading(true);
        setTimeout(async () => {
            try {
                if(getCookie("ref")) values['ref'] = getCookie("ref");
                let resonse = await props.register(values);
                props.history.push(rootRouter.Login);
                NotificationMessage({ type: "success", description: "Đăng ký thành công"})
            } catch (err) {
                setLoading(false);
                setError(err.message);
            }
        }, 600);
    }

    return <React.Fragment>
        <div className="modal-dialog w-100 mx-auto">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                            {/* <div>Welcome back,</div> */}
                            <span>Vui lòng đăng ký vào tài khoản của bạn bên dưới.</span>
                        </h4>
                    </div>
                    <Form
                        onFinish={submitForm}
                        ref={(evt) => form = evt}
                        layout="vertical"
                    >
                        {
                            error ? <Alert className="mb-4" type="error" message={error} /> : ""
                        }
                        <Form.Item name="fullname"
                            label="Họ và tên"
                            rules={[{ required: true }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                        <Form.Item name="username"
                            label="Tài khoản đăng nhập"
                            rules={[{ required: true }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                        <Form.Item name="password"
                            label="Mật khẩu"
                            rules={[{ required: true }]}
                        >
                            <Input.Password size="large" />
                        </Form.Item>
                        <Form.Item
                            name="password-confirm"
                            label="Nhập lại mật khẩu"
                            rules={[
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Confirm wrong!');
                                    },
                                })
                            ]}
                        >
                            <Input.Password placeholder="******" size="large" />
                        </Form.Item>
                        <Button className="d-none" htmlType="submit" id="login"></Button>
                    </Form>
                    <div className="divider"></div>
                    <h6 className="mb-0">Bạn đã có tài khoản? <Link to={rootRouter.Login} className="text-primary">Đăng nhập</Link></h6>
                </div>
                <div className="modal-footer clearfix">
                    {/* <div className="float-left"><a href="#" className="btn-lg btn btn-link">Recover Password</a></div> */}
                    <div className="float-right">
                        <Button type="primary" size="large" className="btn-primary" onClick={(e) => {
                            e.preventDefault();
                            document.querySelector('#login').click();
                        }} loading={loading}>
                            Đăng ký
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}


const mapStateToProp = state => ({
    UserLogin: state.Access.UserLogin,
    loading: state.ClientRequest.loading
});

const mapDispatchToProps = dispatch => ({
    register: value => dispatch(ActionUser.register(value)),
});


export default withRouter(connect(mapStateToProp, mapDispatchToProps)(RegisterPage));