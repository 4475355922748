import rootAction from "Actions/RootAction.json";
import { CreateRequestOption } from "Constants/Utils";
import { Api } from "Constants/ConstantsValue";

export const detail = (params) => {
    return {
        type: rootAction.Affiliate.Detail,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Affiliate.Detail,
        params
    }
}

export const register = (value) => {
    return {
        type: rootAction.Affiliate.Register,
        requestOption: CreateRequestOption("post", value),
        endPoint: Api.Affiliate.Register
    }
}

export const listUser = (params) => {
    return {
        type: rootAction.Affiliate.ListUser,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Affiliate.ListUser,
        params
    }
}

export const listTransaction = (params) => {
    return {
        type: rootAction.Affiliate.ListTransaction,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Affiliate.ListTransaction,
        params
    }
}

export const listWithdraws = (params) => {
    return {
        type: rootAction.Affiliate.ListWithdraws,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Affiliate.ListWithdraws,
        params
    }
}


export const createWithdraws = (value) => {
    return {
        type: rootAction.Affiliate.CreateWithdraws,
        requestOption: CreateRequestOption("post", value),
        endPoint: Api.Affiliate.CreateWithdraws,
    }
}
export default {
    detail,
    register,
    listUser,
    listTransaction,
    listWithdraws,
    createWithdraws
}