import rootAction from "Actions/RootAction.json";

const initialState = {
    domain: [],
    messages: ""
}


const DomainReducer = (state = initialState, action) => {
    switch (action.type) {
        case rootAction.Root.Domain.ListDomain: {
            if (!action.data) return initialState;
            return {
                domain: action.data.data,
                messages: 'success'
            }
        }
        case rootAction.Root.Domain.PostDomain: {
            if (!action.data) return initialState;
            return {
                messages: action.data.messages
            }
        }
        default:
            break;
    }
    return state;
}
export default DomainReducer