import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, message, Image } from "antd";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

const PackageRegister = (props) => {
  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  const onCheckout = () => {

  }

  const onWhile = () => {
  }

  useEffect(() => {

  }, [])

  //main return
  return (
    <div className='flex'>
      <div className="container mar-t40">
        <div className="contents">

          {/* Mã số hóa đơn */}
          <div className="content-1">
            <div className="content-1-text flex justify">
              <p className='content-1-text1'>Mã số hóa đơn: <span style={{ fontWeight: 'bold' }}>33657</span></p>
              <p style={{ color: 'red', marginRight: '400px' }} className='content-1-text2'>CHỜ THANH TOÁN</p>
            </div>
            <div className="content-1-text flex justify">
              <p className='content-1-text3'>Tên khách hàng: <span style={{ fontWeight: 'bold' }}>Tiến Quang</span></p>
              <p style={{ marginRight: '393px' }} className='content-1-text4'>Ngày tạo: <span style={{ fontWeight: 'bold' }}>08/06/2022</span></p>
            </div>
          </div>

          {/* Bank */}
          <div className="content-2">
            <p style={{ textAlign: 'left', fontWeight: 'bold', color: 'rgb(38, 151, 243) ' }} className="content-2-text">Để thanh toán hóa đơn, quý khách vui lòng chuyển tiền vào một trong các tài khoản sau: </p>
            <div className="content-2-cards flex justify">

              {/* Card 2 */}
              <div className="content-2-card">
                <p>Ngân hàng: <span style={{ color: 'rgb(3, 151, 60)' }}>VIETCOMBANK - Chi nhánh Thành Công, Hà Nội</span></p>
                <p>Số tài khoản: <span style={{ fontWeight: 'bold' }}>1014394905</span></p>
                <p>Chủ Tài khoản: <span style={{ fontWeight: 'bold' }}>Bùi Thanh Sơn</span></p>
                <p>Số tiền: <span style={{ fontWeight: 'bold' }}>1.347.000 VND</span></p>
                <p>Nội dung: <span style={{ fontWeight: 'bold' }}>thanh toan hoa don so 33657</span></p>
              </div>

              {/* Card 2 */}
              <div className="content-2-card">
                <p>Ngân hàng: <span style={{ color: 'red' }}>TECHCOMBANK - Chi nhánh Hoàng Quốc Việt, Hà Nội</span></p>
                <p>Số tài khoản: <span style={{ fontWeight: 'bold' }}>1902775143902</span></p>
                <p>Chủ Tài khoản: <span style={{ fontWeight: 'bold' }}>Bùi Thanh Sơn</span></p>
                <p>Số tiền: <span style={{ fontWeight: 'bold' }}>1.347.000 VND</span></p>
                <p>Nội dung: <span style={{ fontWeight: 'bold' }}>thanh toan hoa don so 33657</span></p>
              </div>
            </div>
            <p style={{ color: ' rgb(38, 151, 243)', fontWeight: 'bold' }}>Nếu đã thanh toán, quý khách có thể liên hệ với chúng tôi để xác nhận.</p>
            <button onClick={onCheckout}>✓ Xác nhận thanh toán</button>
          </div>

          {/* Thông tin hóa đơn */}
          <div className="content-3">
            <h4>Thông tin hóa đơn</h4>
            <div className="contentns flex justify">
              <div className="content-3-a">
                <p>Tên sản phẩm,ứng dụng:</p>
                <p>tên gió dịch vụ: </p>
                <p>Các dịch vụ gia tăng: </p>
                <p>Chiết khấu khuyễn mãi: </p>
                <p>Số tiền cần thanh toán: </p>
              </div>
              <div className="content-3-b">
                <p>SOTA</p>
                <p>PREMIUM3</p>
                <p>Số shop mua thêm: 10</p>
                <p>0%</p>
                <p style={{ color: 'rgb(38, 151, 243)' }}>1.347.000 VND</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProp = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default withRouter(
  connect(mapStateToProp, mapDispatchToProps)(PackageRegister)
);
