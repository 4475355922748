import rootAction from "../RootAction.json";
import { CreateRequestOption } from "../../Constants/Utils";
import { Api } from "../../Constants/ConstantsValue";
import qs from "qs";

export const list = (params) => {
    return {
        type: rootAction.Notification.List,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Notification.List+'?'+qs.stringify(params),
    }
}

export const setState = (values) => {
    return {
        type: rootAction.Notification.SetState,
        data: values
    }
}

export default {
    list,
    setState
}