import React from "react";
import { formatPrice } from "Helpers";
import { Button, Modal } from "antd";

import moment from "moment";
import { connect } from "react-redux";

const WidgetDetailTransaction = (props) => {
    const { visible, onCancel, transaction } = props;
    return (
        <Modal
            title=''
            centered
            visible={visible}
            onCancel={onCancel}
            width={1000}
            footer={null}
        >
            {
                transaction ? 
                <div id="detail-transaction">
                    <ul>
                        <li>Mã số hóa đơn: <strong>{transaction.transaction_code}</strong></li>
                        <li className="text-danger">Chờ thanh toán</li>
                        <li>Tên khách hàng: <strong>{transaction.fullname}</strong></li>
                        <li>Ngày tạo: <strong>{moment(transaction.created_time).format("DD/MM/YYYY")}</strong></li>
                    </ul>
                    <div className="card shadow-none">
                        <div className="card-body">
                            <p className="text-primary">Để thanh toán hóa đơn, quý khách vui lòng chuyển tiền vào một trong các tài khoản sau: </p>
                            <div className="card-body-main p-2 row">
                                    {
                                        transaction.banking && transaction.banking.map((item) => {
                                            let banking = item.split("|");
                                            return <div className="col-6">
                                                <p>Ngân hàng: <span style={{ color: 'red' }}>{banking[2]}</span></p>
                                                <p>Số tài khoản: <strong>{banking[0]}</strong></p>
                                                <p>Chủ Tài khoản: <strong >{banking[1]}</strong></p>
                                                <p>Số tiền: <strong>{formatPrice(transaction.amount)} VND</strong></p>
                                                <p>Nội dung: <strong>{transaction.transaction_code}</strong></p>
                                            </div>
                                        })
                                    }
                            </div>
                            <p className="text-center text-primary">Nếu đã thanh toán, quý khách có thể liên hệ với chúng tôi để xác nhận.</p>
                            <div className="text-center">
                                <Button type="primary" className="rounded" onClick={() => onCancel()}>Xác nhận thanh toán</Button>
                            </div>
                        </div>
                    </div>
                    <div className="border-t"></div>
                    <h4>Thông tin hóa đơn</h4>
                    <ul>
                        <li>Tên sản phẩm, ứng dụng:</li>
                        <li>{props.Setting.app_tool.name}</li>
                        <li>Tên gói dịch vụ:</li>
                        <li>{transaction.package_name}</li>
                        <li>Chiết khấu khuyến mãi:</li>
                        <li>{transaction.discount || 0}%</li>
                        <li>Số tiền cần thanh toán:</li>
                        <li>{formatPrice(transaction.amount)} VND</li>
                    </ul>
                </div>
                : null
            }

            {/* {
                transaction ?
                <div className='flex'>
                    <div className="container mar-t40">
                        <div className="contents">

                            <div className="content-1">
                                <div className="content-1-text flex justify">
                                    <p className='content-1-text1'>Mã số hóa đơn: <span style={{ fontWeight: 'bold' }}>{transaction.transaction_code}</span></p>
                                    <p style={{ color: 'red', marginRight: '400px' }} className='content-1-text2'>CHỜ THANH TOÁN</p>
                                </div>
                                <div className="content-1-text flex justify">
                                    <p className='content-1-text3'>Tên khách hàng: <span style={{ fontWeight: 'bold' }}>{transaction.fullname}</span></p>
                                    <p style={{ marginRight: '393px' }} className='content-1-text4'>Ngày tạo: <span style={{ fontWeight: 'bold' }}>{moment(transaction.created_time).format("DD/MM/YYYY")}</span></p>
                                </div>
                            </div>

                            <div className="content-2">
                                <p style={{ textAlign: 'left', fontWeight: 'bold', color: 'rgb(38, 151, 243) ' }} className="content-2-text">Để thanh toán hóa đơn, quý khách vui lòng chuyển tiền vào một trong các tài khoản sau: </p>
                                <div className="content-2-cards flex justify">
                                    {
                                        transaction.banking && transaction.banking.map((item) => {
                                            let banking = item.split("|");
                                            return <div className="content-2-card">
                                                <p>Ngân hàng: <span style={{ color: 'red' }}>{banking[2]}</span></p>
                                                <p>Số tài khoản: <span style={{ fontWeight: 'bold' }}>{banking[0]}</span></p>
                                                <p>Chủ Tài khoản: <span style={{ fontWeight: 'bold' }}>{banking[1]}</span></p>
                                                <p>Số tiền: <span style={{ fontWeight: 'bold' }}>{formatPrice(transaction.amount)} VND</span></p>
                                                <p>Nội dung: <span style={{ fontWeight: 'bold' }}>thanh toan hoa don {transaction.transaction_code}</span></p>
                                            </div>
                                        })
                                    }
                                </div>
                                <p style={{ color: ' rgb(38, 151, 243)', fontWeight: 'bold' }}>Nếu đã thanh toán, quý khách có thể liên hệ với chúng tôi để xác nhận.</p>
                                <button>Xác nhận thanh toán</button>
                            </div>

                        </div>
                    </div>
                </div>
                : null
            } */}
        </Modal>
    )
}

const mapStateToProp = (state) => ({
    Setting: state.Access.Setting,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProp, mapDispatchToProps)(WidgetDetailTransaction);