import React from "react";
import { formatPrice } from "Helpers";
import { Button } from "antd";

const ItemPackage = (props) => {
    const { item, onSubmit, col, listFunction } = props;
    const roles = item.extra_data && item.extra_data.roles ? item.extra_data.roles  : [];

    const convertTime = (time) => {
        let result = `${time} ngày`;
    }

    return <div className={`${col ? "col-md-" + col : "col-md-3"} d-flex`} key={item.id}>
        <div className="card w-100">
            <div className="card-hotpic">
                {item.is_hot ? <img className="hot" src="/icons/hot-fococlipping-standard.png" alt='hot' width={50} height={50} /> : null}
            </div>
            <div className="card-body">
                <div className="card-title">
                    <img className="decoration-lines" src="/icons/decoration-lines.svg" alt="alternative" width={30} height={9.75} />
                    <span>{item.name}</span>
                    <img className="decoration-lines flipped" src="/icons/decoration-lines.svg" alt="alternative" width={30} height={9.75} />
                </div>
                <ul className="list-unstyled li-space-lg">
                    {
                        item.extra_data && item.extra_data.num_channel ?
                        (
                            item.extra_data.num_channel == -1 ? 
                            <li>Không giới hạn cửa hàng</li>
                            : <li>{item.extra_data.num_channel} cửa hàng</li>
                        )
                        : null
                    }
                    {
                        listFunction ? 
                        listFunction.map((_item, i) => {
                            return <li key={i} className={roles.indexOf(_item.value) != -1 ? "" : "line-through"}>{_item.label}</li>
                        })
                        : null
                    }
                </ul>
                {
                    item.discount ?
                    <div className="d-flex justify-content-center align-items-center">
                        <del>{formatPrice(item.price)}</del>
                        <div className="price text-danger">{formatPrice(Math.floor((item.price/100) * (100-item.discount)))}<span>đ</span></div>
                    </div>
                    : 
                    <div className="price">{formatPrice(item.price)}<span>đ</span></div>
                }
                <h3 style={{ marginBottom: '30px', marginTop: "30px" }}>Thời hạn: {item.time} ngày</h3>
            </div>
            <div className="card-footer border-0 justify-content-center">
                <Button type="primary" onClick={() => onSubmit(item)}>
                    Đăng ký
                </Button>
            </div>
        </div>
    </div>
}

export default ItemPackage;