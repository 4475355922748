import React, { Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {withRouter} from 'react-router-dom';

import ResizeDetector from 'react-resize-detector';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import AppLoadingScreen from './AppLoading';

import { authDetail, logOut } from "../Actions/Access";
import { IsAuthen } from 'Constants/Utils';

const MainLayout = (props) => {
    // console.log(props.children);
    let {
        colorScheme,
        enableFixedHeader,
        enableFixedSidebar,
        enableFixedFooter,
        enableClosedSidebar,
        closedSmallerSidebar,
        enableMobileMenu,
        enablePageTabsAlt,
    } = props;

    useEffect(async () => {
        if(IsAuthen()){
            props.getAuthDetail();
        } else{
            props.logOut();
        }
    }, [])

    return (
        !props.UserLogin ? 
            <AppLoadingScreen />
        :
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Fragment>
                    <div className={cx(
                        "app-container app-theme-" + colorScheme,
                        {'fixed-header': enableFixedHeader},
                        {'fixed-sidebar': enableFixedSidebar || width < 1250},
                        {'fixed-footer': enableFixedFooter},
                        {'closed-sidebar': enableClosedSidebar || width < 1250},
                        {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
                        {'sidebar-mobile-open': enableMobileMenu},
                    )}>
                        <span class="lnr lnr-home"></span>
                        <Fragment>
                        <AppHeader/>
                        <div className="app-main">
                            <AppSidebar />
                            <div className="app-main__outer">
                                <div className="app-main__inner">
                                    {props.children}
                                </div>
                                <AppFooter/>
                            </div>
                        </div>
                    </Fragment>
                    </div>
                </Fragment>
            )}
        />
    )
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
    UserLogin: state.Access.UserLogin
});

const mapDispatchToProps = dispatch => ({
    getAuthDetail: value => dispatch(authDetail(value)),
    logOut: value => dispatch(logOut(value)),
});

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(MainLayout));