import rootAction from "Actions/RootAction.json";

const initialState = {
    affiliate: null,
    transaction: {
        total: 0,
        listData: []
    },
    user: {
        total: 0,
        listData: []
    },
    withdraws: {
        total: 0,
        listData: []
    }
}

const Affiliate = (state = initialState, action) => {
    switch (action.type) {
        case rootAction.Affiliate.Detail:
            if (!action.data) return initialState;
            return {
                ...state,
                affiliate: action.data.affiliate
            }
        case rootAction.Affiliate.ListTransaction:
            if (!action.data) return initialState;
            return {
                ...state,
                transaction: {
                    total: action.data.total,
                    listData: action.data.data
                }
            }
        case rootAction.Affiliate.ListUser:
            if (!action.data) return initialState;
            return {
                ...state,
                user: {
                    total: action.data.total,
                    listData: action.data.data
                }
            }
        case rootAction.Affiliate.ListWithdraws:
            if (!action.data) return initialState;
            return {
                ...state,
                withdraws: {
                    total: action.data.total,
                    listData: action.data.data
                }
            }
        default:
            break;
    }
    return state;
}

export default Affiliate;