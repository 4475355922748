import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Modal, Select, Button } from "antd";
import { } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { ActionAdminAffiliate, ActionAdminTransaction, ActionAdminUser, ActionUser } from "Actions";
import { NotificationMessage } from "Constants/Utils";

const UpdateModal = (props) => {
    const { onCancel, visible, item, onFinish } = props;
    const [loading, setloading] = useState(false);

    const formRef = useRef();

    useEffect(() => {
        if (visible && item) {
            formRef.current.setFieldsValue({
                status: item.status || null,
                roles: item.roles,
            });
        }
    }, [visible]);

    const submitForm = async (values) => {
        setloading(true);
        try {

            let res = await props.update(item._id, values);

            NotificationMessage({
                type: "success",
                description: "Thao tác thành công",
            });

            onCancel();

            if (onFinish) onFinish();
        } catch (err) {
            NotificationMessage({
                type: "warning",
                response: err,
            });
        }
        setloading(false);
    };

    return (
        <Modal
            visible={visible}
            title="Cập nhập dữ liệu"
            onCancel={onCancel}
            footer={null}
        >
            <Form name="basic" onFinish={submitForm} layout="vertical" ref={formRef}>
                <Form.Item label="Trạng thái" name="status">
                    <Select placeholder="Chọn trạng thái">
                        {Object.keys(props.Config.statusTransaction).map((key) => {
                            let item = props.Config.statusTransaction[key];

                            return key != 0 && <Select.Option value={parseInt(key)}>{item.label}</Select.Option>;
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label="Ghi chú" name="note">
                    <Input.TextArea style={{minHeight: "120px"}}/>
                </Form.Item>
                <Form.Item className="mb-0 text-right">
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Xác nhận
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProp = (state) => ({
    Config: state.Config,
});

const mapDispatchToProps = (dispatch) => ({
    update: (id, value) => dispatch(ActionAdminAffiliate.updateWithdraws(id, value)),
});

export default connect(mapStateToProp, mapDispatchToProps)(UpdateModal);
