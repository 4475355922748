import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";


const RootIndex = (props) => {


  return (
    <div>
      <h1>RootIndex</h1>

    </div>
  )
}

const mapStateToProp = state => ({

});

const mapDispatchToProps = dispatch => ({

});


export default withRouter(connect(mapStateToProp, mapDispatchToProps)(RootIndex));