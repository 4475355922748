import rootAction from "./RootAction.json";

import { CreateRequestOption } from "Constants/Utils";
import { Api } from "Constants/ConstantsValue";

export const beforeRequest = (payload, values) => {
    return {
        type: rootAction.ClientRequest.BeforeRequest,
        payload: payload,
        sendValues: values
    }
}

export const afterRequest = (payload, values, response) => {
    return {
        type: rootAction.ClientRequest.AfterRequest,
        payload: payload,
        sendValues: values,
        response
    }
}

export const submitRequest = (type, values) => {
    return {
        type: rootAction.ClientRequest.SubmitRequest,
        requestOption: CreateRequestOption("post", values),
        endPoint: Api.SubmitRequest[type.slice(0,1,type[0].toUpperCase())]
    }
}

export default {
    submitRequest
}