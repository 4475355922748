import { AuditLog, AuthCookieName } from "./ConstantsValue";
import getHistory from "../Components/Common/GlobalHistory";
import { ClearAuditLog } from "../Helpers/Logs/AuditLog";
import { messageError } from "../Helpers";

import { notification, Modal, message } from "antd";
import moment from "moment";

const md5 = require("md5");
const { SHA256 } = require("crypto-js");

export const IsAuthen = () =>  getCookie(AuthCookieName) ? true : false;

export const SetAuthCookie = (value) => setCookie(AuthCookieName, value, 30);

export const CreateRequestOption = (method, params) => {
    var headers = {};

    if (IsAuthen()) {
        headers = {
            'Content-Type': 'application/json',
            'auditlog': localStorage.getItem(AuditLog),
            'Authorization': getCookie(AuthCookieName)
        };
    }
    else {
        headers = {
            'Content-Type': 'application/json',
        };
    }

    return {
        method: method,
        headers: headers,
        credentials: 'include',
        body: params && method.toLocaleLowerCase() != "get" ? JSON.stringify(params) : null
    };
}

export const RedirectWithComponent = (url, params) => {
    const history = getHistory();
    history.push({
        pathname: url,
        state: params ?? {}
    });
};

export const RedirectUrl = (url) => {
    ClearAuditLog();
    window.location.href = url;
}

export function setCookie(name, value, days, domain) {
    var expires = "";
    if (days) {
        expires = "; expires=" + moment().add(days, "day").toLocaleString();
    }
    document.cookie = name + "=" + (value || "") + expires + ";domain="+(domain || "")+"; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const ConvertValueFromUrl = {
    shopee: {
        getProductId: (url) => {
            let product_url = new URL(url);
            let item_id = null, shop_id= null;
            try {
                if(product_url.pathname.indexOf('-i.') > -1){
                    let product_id = product_url.pathname.slice(product_url.pathname.indexOf('-i.'));
                    shop_id = product_id.split('.')[1];
                    item_id = product_id.split('.')[2];
                } else if(product_url.pathname.indexOf('/products/') == 0){
                    shop_id = product_url.pathname.split('/')[2];
                    item_id = product_url.pathname.split('/')[3];
                }
            } catch (err) {
    
            }
            return {item_id, shop_id };
        }
    }
}

export const NotificationMain = ({ type, description, message}) => {
    if(type == "error"){
        description = description ? description : "An error occurred"
    }
    notification[type]({
        description: description,
        message: message || "Notification",
        placement: 'topRight',
        duration: 3,
        rtl: true,
    })
}

export const HashPasswordShopee = (e) => {
    return SHA256(md5(e)).toString()
}

export const MakeDeviceId = () => {
    var e, t;
    // "string" == typeof e && (t = "binary" == e ? new Array(16) : null, e = null);
    var a = (e = e || {}).random || (e.rng || function () {
        return function (e) {
            for (var t = 0, a = e.length; t < a; t++) e[t] = Math.floor(256 * Math.random());
            return e
        }(new Uint8Array([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]))
    })();
    if (a[6] = 15 & a[6] | 64, a[8] = 63 & a[8] | 128, t)
        for (var r = 0; r < 16; ++r) t[0 + r] = a[r];
    return t || function (e) {
        for (var a = [], r = 0; r < 256; ++r) a[r] = (r + 256).toString(16).substr(1);
        r = t || 0;
        var n = a;
        return n[e[r++]] + n[e[r++]] + n[e[r++]] + n[e[r++]] + "-" + n[e[r++]] + n[e[r++]] + "-" + n[e[r++]] + n[e[r++]] + "-" + n[e[r++]] + n[e[r++]] + "-" + n[e[r++]] + n[e[r++]] + n[e[r++]] + n[e[r++]] + n[e[r++]] + n[e[r++]]
    }(a)
}

export const CookiesToString = (cookies = []) => {
    return cookies.map((item) => {
        return `${item.name}=${item.value}`
    }).join(";")
}

export const NotificationMessage = ({ type, response, description, title, key, message_code, error_code}, t) => {
    try{
        title = title || "Notification";
        if(response){
            if(type == "error" || type == "warning"){
                title = title || "Warning";
                description = description || "An error occurred";
                if(typeof response == "object"){
                    if(response.error_code && t){
                        description = t(`error.${response.error_code}`, {ns: "message" })
                    } else if(response.message){
                        description = response.message;
                    } else{
                        description = response.error_code;
                    }
                    if(response.error_code) error_code = response.error_code;

                } else if(typeof response == "string"){
                    description = response;
                }
            }
        } else if(type == "success"){
            if(t){
                title = t("notify", { ns: "common" });
                title = title || "Notify default";
                if(message_code){
                    description = t(message_code, { ns: "message"})
                }
            } else if(message_code){
                description = message_code;
            }
        }

        if(error_code === "cancel_action") return;

        notification[type]({
            description: description,
            message: title,
            placement: 'topRight',
            duration: 3,
            rtl: true,
            key: key
        })
    } catch(err){
        console.log(err);
    }
}