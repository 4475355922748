import rootAction from "../Actions/RootAction.json";

const initialState = {
    loading: false
}

const clientRequest = (state = initialState, action) => {

    switch (action.type) {
        case rootAction.ClientRequest.BeforeRequest:
            return {
                ...state,
                loading: true
            }

        case rootAction.ClientRequest.AfterRequest:
            return {
                ...state,
                loading: false
            }
        
        default:
            break;
    }

    return initialState;
}

export default clientRequest;