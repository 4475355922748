import Error from "../Pages/Error";

import MainLayout from "../Layout/mainLayout";
import LoginLayout from "../Layout/LoginLayout";

import Router from "Resources/Router.json";

import {
  LoginPage,
  RegisterPage,
  LogoutPage,
  LoginRedirectPage,
  UserPage,
  PackagePage,
  PackageRegisterPage,
  TransactionPage,
  AdminTransactionPage,
  AdminUserPage,
  AdminSetting,
  AffiliatePage,
  WithdrawsPage,
  AdminAffiliatePage,
  AdminAffiliatewWithdrawsPage,
  RootIndexPage,
  RootDomainPage,
  RootToolsPage,
} from "../Pages";

export const AllRouter = [
  {
    Layout: LoginLayout,
    Path: [
      `${Router.Login}`,
      `${Router.Error}`,
      `${Router.Register}`,
      `${Router.Logout}`,
      `${Router.LoginRedirect}`
    ],
    Routers: [
      { path: `${Router.Login}`, component: LoginPage, exact: true },
      { path: `${Router.Register}`, component: RegisterPage, exact: true },
      { path: `${Router.Error}`, component: Error },
      { path: `${Router.Logout}`, component: LogoutPage },
      { path: `${Router.LoginRedirect}`, component: LoginRedirectPage },
    ],
    
  },

  {
    Layout: LoginLayout,
    Path: [
      `${Router.Root.Login}`,
      `${Router.Error}`,
      `${Router.Logout}`,
      `${Router.LoginRedirect}`
    ],
    Routers: [
      { path: `${Router.Root.Login}`, component: LoginPage, exact: true },
      { path: `${Router.Error}`, component: Error },
      { path: `${Router.Logout}`, component: LogoutPage },
      { path: `${Router.LoginRedirect}`, component: LoginRedirectPage },
    ],
  },
  // {
  //   Layout: null,
  //   Routers: [

  //   ]
  // },
  {
    Layout: MainLayout,
    Routers: [
      { path: `${Router.SubError.System}`, component: Error, exact: true },
      {
        path: `${Router.Admin.Config}`,
        component: AdminSetting,
        exact: true,
      },
      {
        path: `${Router.Home}`,
        component: UserPage,
        exact: true,
      },
      {
        path: `${Router.Package.Index}`,
        component: PackagePage,
        exact: true,
      },
      {
        path: `${Router.Package.Register}`,
        component: PackageRegisterPage,
        exact: true,
      },
      {
        path: `${Router.Transaction.Index}`,
        component: TransactionPage,
        exact: true,
      },
      {
        path: `${Router.Admin.Transaction}`,
        component: AdminTransactionPage,
        exact: true,
      },
      {
        path: `${Router.Admin.User}`,
        component: AdminUserPage,
        exact: true,
      },
      {
        path: `${Router.Affiliate.Index}`,
        component: AffiliatePage,
        exact: true,
      },
      {
        path: `${Router.Affiliate.ListWithdraws}`,
        component: WithdrawsPage,
        exact: true,
      },
      {
        path: `${Router.Admin.Affiliate.User}`,
        component: AdminAffiliatePage,
        exact: true,
      },
      {
        path: `${Router.Admin.Affiliate.Withdraws}`,
        component: AdminAffiliatewWithdrawsPage,
        exact: true,
      },
      {
        path: `${Router.Root.Login}`,
        component: LoginPage,
        exact: true,
      },
      {
        path: `${Router.Root.Domain}`,
        component: RootDomainPage,
        exact: true,
      },
      {
        path: `${Router.Root.Tool}`,
        component: RootToolsPage,
        exact: true,
      },
      {
        path: `${Router.Root.Index}`,
        component: RootIndexPage,
        exact: true,
      },
    ],
  },
  // {
  //   Layout: MainLayout,
  //   Routers: [
  //     { path: `${Router.SubError.System}`, component: Error, exact: true },
      
  //   ]
  // },
];
