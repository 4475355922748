import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "Config/store";
import { CookiesProvider } from "react-cookie";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import "antd/dist/antd.css";
import "./Assets/base.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { resourcesLanguage } from "./i18n";

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("locale") || "vi",
  resources: resourcesLanguage,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
