import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Alert, Result, Button } from "antd";
import { Link, withRouter } from "react-router-dom";

const ErrorPage = (props) => {
    let { status, ErrorId, message } = props.location.state || {};

    return (
        <React.Fragment>
            <Result
                status={status && status != 400 ? status : "404"}
                title={<div style={{color: "#fff"}}>{status || "404"}</div>}
                subTitle={<div style={{color: "#fff"}}>{message || "Sorry, you are not authorized to access this page."}</div>}
                extra={<Link className="ant-btn ant-btn-primary" to="/">Back Home</Link>}
            />
        </React.Fragment>
    );
}

export default withRouter(ErrorPage);