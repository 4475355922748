import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { ActionRoot } from "Actions";
import { Button, Space, Table, Modal, Input, Select, Tag, Form } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';

import { NotificationMessage } from 'Constants/Utils';
const { Option } = Select;




function Domain(props) {

  const { getList, listDomain, postListDomain, messages } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [pagination, setpagination] = useState({
    page: 1,
    limit: 20,
  });
  const formFiler = useRef();
  const timeOutFilter = useRef();
  const [form] = Form.useForm()

  const [input, setInput] = useState({
    domain: "",
    tool_id: "",
    extra_domain: [],
    link_fanpage: "",
    link_zalo: "",
    status: 1
  });
  let [data, setData] = useState({
    domain: "",
    tool_id: "",
    extra_domain: "",
    link_fanpage: "",
    link_zalo: "",
    status: 1
  })

  const showModal = (e) => {

    setIsModalVisible(true)

    if (e === 'Btn') {
      setUpdate(false)
      setData(listDomain)
      if (input) {
        form.setFieldsValue({
          name: "",
          tool_id: "",
          extra_domain: [],
          link_fanpage: "",
          link_zalo: "",
          status: "",
        });
      }
    } else if (e !== 'Btn') {
      setUpdate(true)
      form.setFieldsValue({
        name: listDomain[e].domain,
        tool_id: listDomain[e].tool_id,
        extra_domain: listDomain[e].extra_domain,
        link_fanpage: listDomain[e].link_fanpage,
        link_zalo: listDomain[e].link_zalo,
        status: listDomain[e].status
      });
      setInput({
        domain: listDomain[e].domain,
        tool_id: listDomain[e].tool_id,
        extra_domain: listDomain[e].extra_domain,
        link_fanpage: listDomain[e].link_fanpage,
        link_zalo: listDomain[e].link_zalo,
        status: listDomain[e].status
      })
      const update = listDomain.filter((item, index) => index === e)
      setData(update)
    }
  }

  const submitForm = (e) => {
    if (input.domain !== "" && input.tool_id !== "" && update === false) {
      postListDomain(input)
      setLoading(true)
      setIsModalVisible(false);
      NotificationMessage({ type: messages, response: messages });
    } else if (e && input.domain !== "" && input.tool_id !== "" && update === true) {
      postListDomain(input)
      setUpdate(false)
      getList()
      setIsModalVisible(false)
      NotificationMessage({ type: messages, response: messages });
    }
  }

  const handleCancel = () => {
    setUpdate(false)
    setIsModalVisible(false);
  }

  const handleOnChangeInput = (e) => {
    const newData = { ...input }
    newData[e.target.id] = e.target.value
    setInput(newData);
    console.log(input);
  }

  const handleChange = (value) => {
    if (value === 1 || value === 2) {
      input.status = value
    } else {
      input.extra_domain = value
    }
    console.log(input);
  };

  useEffect(() => {
    getListData();
  }, [pagination]);

  const getListData = async () => {
    // debugger
    setLoading(true);
    try {
      let params = { ...pagination };

      if (formFiler.current) params = { ...params, ...formFiler.current.getFieldsValue() }

      await props.getList(params);
    } catch (err) {
      NotificationMessage({ type: "warning", response: err });
    }
    setLoading(false);
  };

  const filterData = (value) => {
    // debugger
    let timeout = 0;

    if (value.domain) {
      timeout = 300;
    }

    if (timeOutFilter.current) clearTimeout(timeOutFilter.current);

    timeOutFilter.current = setTimeout(() => {
      getListData();
    }, timeout);
  }

  useEffect(() => {
    getList();
  }, [])

  return (
    <div className="main-card card shadow-none">
      <div className="card-header d-flex justify-content-between">
        <h5>Danh sách tên miền: {listDomain && listDomain.length}</h5>
      </div>
      <div className="card-body">
        <div>
          <Button type="primary" onClick={() => showModal('Btn')}>
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faPlus} />
            Thêm mới
          </Button>
          <div className="mt-3">
            <Form
              ref={formFiler}
              onValuesChange={filterData}
              onReset={getListData}
            >
              <div className="row">
                <div className="col-md-3">
                  <Form.Item name="domain">
                    <Input placeholder={"Nhập tên miền..."} />
                  </Form.Item>
                </div>
                <div className="col-md-2">
                  <Form.Item name="status">
                    <Select placeholder="Trạng thái" allowClear>
                      {
                        Object.keys(props.config.statusRoot).map((key) => {
                          let item = props.config.statusRoot[key];
                          return <Select.Option value={item.value}>{item.label}</Select.Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-auto">
                  <Form.Item>
                    <Button htmlType="reset">Làm mới</Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <Modal title={update === true ? `Sửa tên miền: "${input.domain}"` : "Thêm tên miền"}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form form={form}
            name="basic"
            onFinish={submitForm}
            layout="vertical"

          >
            <Form.Item label="Tên miền" name="name">
              <Input disabled={update === true} type="text" name="domain" id="domain" value={input.domain} onChange={(e) => handleOnChangeInput(e)} placeholder='Nhập tên miền' />
            </Form.Item>
            <Form.Item label="Tool id" name="tool_id">
              <Input type="text" name="tool_id" id="tool_id" value={input.tool_id} onChange={(e) => handleOnChangeInput(e)} placeholder='Nhập tool id' />
            </Form.Item>
            <Form.Item label="Trạng thái" name="status">
              <Select

                style={{
                  width: '100%',
                }}
                onChange={handleChange}
                placeholder='Nhập trạng thái'
              >
                {
                  Object.keys(props.config.statusRoot).map((key) => {
                    let item = props.config.statusRoot[key];
                    return <Select.Option value={item.value}>{item.label}</Select.Option>
                  }
                  )}
              </Select>
            </Form.Item>
            <Form.Item label="Tên miềm phụ" name="extra_domain">
              <Select
                mode="multiple"
                style={{
                  width: '100%',
                }}
                placeholder="chọn các tên miền phụ"
                onChange={handleChange}
                optionLabelProp="label"
              >
                {
                  Object.keys(props.config.extraDomain).map((key) => {
                    let item = props.config.extraDomain[key];
                    return <Select.Option value={item.value} label={item.label}>{item.label}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item label="Link Fanpage" name="link_fanpage">
              <Input type="text" name="link_fanpage" id="link_fanpage" value={input.link_fanpage} onChange={(e) => handleOnChangeInput(e)} placeholder='Nhập link fanpage' />
            </Form.Item>
            <Form.Item label="Link Zalo" name="link_zalo">
              <Input type="text" name="link_zalo" id="link_zalo" value={input.link_zalo} onChange={(e) => handleOnChangeInput(e)} placeholder='Nhập link zalo' />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary" loading={loading}>Xác nhận</Button>
            </Form.Item>
          </Form>
        </Modal>
        <Table columns={[
          {
            title: 'STT',
            dataIndex: "1",
            key: 'ID',
            render: (text, item, index) => <a>{index + 1}</a>,
          },
          {
            title: 'Tên miền',
            dataIndex: 'domain',
            key: 'domain',
            render: (text) => <a>{text}</a>,
          },
          {
            title: 'Tool id',
            dataIndex: 'tool_id',
            key: 'tool_id',
            render: (text) => <a>{text}</a>,
          },
          {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (value) => {
              return props.config.statusUser[value] && <React.Fragment>
                <span className={`text-${props.config.statusRoot[value].color}`}>{props.config.statusRoot[value].label}</span>
              </React.Fragment>
            }
          },
          {
            title: 'Extra domain',
            key: 'extra_domain',
            dataIndex: 'extra_domain',
            render: (_, { extra_domain }) => (
              <>
                {
                  extra_domain &&
                  extra_domain.map &&
                  extra_domain.map((extra_domain, index) => {
                    let color = extra_domain.length > 5 ? 'geekblue' : 'green';


                    if (index === 0 || index === 2 || index === 4 || index === 6 || index === 8) {
                      color = 'volcano';
                    }
                    return (
                      <Tag className='mt-2' color={color} key={extra_domain}>
                        {extra_domain.toUpperCase()}
                      </Tag>
                    );
                  })}
              </>
            ),
          },
          {
            title: 'Link Facebook',
            dataIndex: 'link_fanpage',
            key: 'link_fanpage',
            render: (text) => <a>{text}</a>,
          },
          {
            title: 'Link Zalo',
            dataIndex: 'link_zalo',
            key: 'link_zalo',
            render: (text) => <a>{text}</a>,
          },
          {
            title: 'Hành động',
            key: 'action',
            render: (_, record, index) => (
              <Space size="middle">
                <Button onClick={(e) => showModal(index)}><FontAwesomeIcon icon={faEdit} /></Button>
              </Space>
            ),
          },
        ]}
          dataSource={listDomain}
          rowKey="_id"
          loading={loading}
          pagination={{
            pageSize: pagination.limit,
            current: pagination.page,
            onChange: (page, pageSize) =>
              setpagination({ page: page, limit: pageSize }),
          }}
          scroll={{
            x: true
          }}
        />
      </div>
    </div>
  );
}

const mapStateToProp = (state) => ({
  listDomain: state.RootDomain.domain,
  messages: state.RootDomain.messages,
  config: state.Config
});

const mapDispatchToProps = (dispatch) => ({
  getList: (value) => dispatch(ActionRoot.listDomain(value)),
  postListDomain: (value) => dispatch(ActionRoot.postListDomain(value))
});


export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Domain));