import React, { useRef, useState } from "react";
import { connect } from "react-redux";

import { Table, Input, Form, Alert, Button, Modal } from "antd";

import WidgetInfoLevel from "./infoLevel";
import WidgetPolicy from "./policy";
import { NotificationMessage } from "Constants/Utils";
import { ActionAffiliate } from "Actions";

const WidgetRegister = (props) => {
    const [visible, setvisible] = useState(true);
    const [loading, setloading] = useState(false);

    const form = useRef();

    const onSubmit = async (values) => {
        setloading(true);
        try {
            await props.register(values);
            await props.getDetail();
            NotificationMessage({ type: "success", description: "Đăng ký thành công" });
            setvisible(false);
        } catch (err) {
            NotificationMessage({ type: "warning", response: err });
        }
        setloading(false);
    }

    return <React.Fragment>
        <div className="card shadow-none mb-3">
            <Modal
                title={'Đăng ký Affiliate'}
                visible={visible}
                onCancel={() => setvisible(false)}
                footer={null}
            >
                <Form
                    name="basic"
                    layout={"vertical"}
                    ref={form}
                    initialValues={{
                        ...props.UserLogin
                    }}
                    onFinish={onSubmit}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Email là bắt buộc' }]}
                        label="Email"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        rules={[{ required: true, message: 'Số điện thoại là bắt buộc' }]}
                        label="Số điện thoại"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="address"
                        rules={[{ required: true, message: 'Địa chỉ là bắt buộc' }]}
                        label="Địa chỉ"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="link_facebook"
                        label="Link facebook"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="link_website"
                        label="Link website / blog"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item className="mb-0 text-right">
                        <Button className="mr-2" onClick={() => setvisible(false)}>
                            Đóng
                        </Button>
                        <Button htmlType="submit" type="primary" loading={loading}>
                            Đăng ký
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <div className="card-header">
                <h5>Đăng ký Affiliate</h5>
            </div>
            <div className="card-body">
                <div>
                    <Alert
                        message="Thông báo"
                        description={<p>Bạn chưa đăng ký tài khoản Affiliate! vui lòng liên hệ tới chúng tôi nếu có bất cứ thắc mắc gì!</p>}
                        type="info"
                    />
                </div>
                <div className="mt-3">
                    <Button type="primary" onClick={() => setvisible(true)}>Đăng ký ngay</Button>
                </div>
            </div>
        </div>
        {/* <div className="row mt-3">
            <div className="col-md-6">
                <WidgetInfoLevel />
            </div>
            <div className="col-md-6">
                <WidgetPolicy />
            </div>
        </div> */}
    </React.Fragment>
}

const mapStateToProp = (state) => ({
    Setting: state.Access.Setting,
    UserLogin: state.Access.UserLogin
})

const mapDispatchToProps = (dispatch) => ({
    register: value => dispatch(ActionAffiliate.register(value)),
    getDetail: value => dispatch(ActionAffiliate.detail(value))
})

export default connect(mapStateToProp, mapDispatchToProps)(WidgetRegister)