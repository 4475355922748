import React from 'react';

export default function AppLoadingScreen(){
    return <div className="loader-container">
        <div className="loader-container-inner">
            {/* <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
            </h6> */}
            <div className="loading-screen bg-primary"></div>
        </div>
    </div>
}