import Router from "Resources/Router.json";

export const MainNav = [
  {
    title: "app_sidebar.heading.1",
    menus: [
      {
        icon: "pe-7s-user",
        label: "1",
        to: Router.Home,
      },
      {
        icon: "pe-7s-cloud",
        label: "2",
        to: Router.Package.Index,
      },
      {
        icon: "pe-7s-note2",
        label: "4",
        to: Router.Transaction.Index,
      },
      {
        icon: "pe-7s-share",
        label: "3",
        to: Router.Affiliate.Index,
      },
    ]
  },
  
  {
    title: "app_sidebar.heading.2",
    menus: [
      {
        icon: "pe-7s-paper-plane",
        label: "7",
        to: "config:link_fanpage"
      },
      {
        icon: "pe-7s-call",
        label: "8",
        to: "config:link_zalo"
      }
    ]
  },
  {
    title: "app_sidebar.heading.3",
    roles: ["admin"],
    menus: [
      {
        icon: "pe-7s-settings",
        label: "5",
        to: Router.Admin.Config,
      },
      {
        icon: "pe-7s-note2",
        label: "9",
        to: Router.Admin.Transaction,
      },
      {
        icon: "pe-7s-users",
        label: "10",
        to: Router.Admin.User,
      },
      {
        icon: "pe-7s-share",
        label: "11",
        content: [
          {
            label: '12',
            to: Router.Admin.Affiliate.User,
          },
          {
            label: '13',
            to: Router.Admin.Affiliate.Withdraws,
          },
        ]
      }
    ],
  },
  {
    title_root: "app_sidebar.heading.4",
    is_root: ["root"],
    menus_root: [
      {
        icon: "pe-7s-user",
        label: "1",
        to: Router.Home,
      },
      {
        icon: "pe-7s-cloud",
        label: "15",
        to: Router.Root.Domain,
      },
      {
        icon: "pe-7s-tools",
        label: "16",
        to: Router.Root.Tool,
      },
    ]
  },
];
