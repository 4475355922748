import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { GlobalHistory } from './Components/Common/GlobalHistory';
import { getCookie, RedirectWithComponent, setCookie } from "./Constants/Utils"

import { AllRouter } from './Router';
import { ErrorPage } from './Pages';

import { ExportDataComponent } from './Components';

class App extends Component {

  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);
    if(params.get("ref") && !getCookie("ref")){
      setCookie("ref", params.get("ref"), 30)
    }
  }

  render() {
    return (
      <div className="App">
        <Router>
          <GlobalHistory />
          <ExportDataComponent />

          <Switch>
            {
              AllRouter.map((layout, layoutKey) => (
                <layout.Layout path={layout.Path} key={layoutKey}>
                  <Switch>
                    {
                      layout.Routers.map((route, routeKey) => {
                        let props_other = route.props || {};
                        return (
                          <Route
                            key={routeKey}
                            exact={route.exact}
                            path={route.path}
                            render={props => (
                              <route.component {...props} {...props_other}/>
                            )}
                          />
                        )
                      })
                    }
                    <Route render={props => <ErrorPage status="404" title="404" subTitle="Sorry, you are not authorized to access this page" />} />
                  </Switch>
                </layout.Layout>
              ))
            }
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;


