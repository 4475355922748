import rootAction from "Actions/RootAction.json";

const initialState = {
    listData: [],
    total: 0
}

const Transaction = (state = initialState, action) => {
    switch (action.type) {
        case rootAction.Admin.Transaction.List:
            if (!action.data) return initialState;
            return {
                ...state,
                total: action.data.total,
                listData: action.data.data
            }
        default:
            break;
    }
    return state;
}

export default Transaction;