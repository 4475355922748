import rootAction from "Actions//RootAction.json";
import { CreateRequestOption } from "Constants/Utils";
import { Api } from "Constants/ConstantsValue";
import qs from "qs";

export const create = (value) => {
    return {
        type: rootAction.Package.Create,
        requestOption: CreateRequestOption("post", value),
        endPoint: Api.Transaction.RegisterPackage
    }
}

export default {
    create
}