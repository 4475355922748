import React, {Fragment, useEffect} from 'react';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';

const NavLink = (e) => {
    // debugger
    if(e.to.indexOf("config:") == 0){
        let href = e.Setting[e.to.replace("config:", "")];
        if(!href) return null;
        return <a href={href} target="_blank" className={cx(e.className)}>
            {e.children.map((item, i) => {
                if(i == 1) item = e.t(`app_sidebar.menu.${item}`);
                return item
            })}
        </a>
    }
    return <Link to={e.to} className={cx(e.className, e.location.pathname == e.to ? "active" : "", e.hasSubMenu ? "" : "")} onClick={e.hasSubMenu ? e.toggleSubMenu : e.activateMe}
    >
        {e.children.map((item, i) => {
            if(i == 1) item = e.t(`app_sidebar.menu.${item}`);
            return item
        })}
    </Link>
}

const mapStateToProp = (state) => ({
    Setting: state.Access.Setting
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProp, mapDispatchToProps)(withTranslation("common")(withRouter(NavLink)));