import rootAction from "./RootAction.json";
import { CreateRequestOption } from "../Constants/Utils";
import { Api } from "../Constants/ConstantsValue";

export const logOut = (params) => {
    return {
        type: rootAction.Access.Logout,
        params
    }
}

export const postLogin = (value) => {
    return {
        type: rootAction.Access.Login,
        requestOption: CreateRequestOption("post", value),
        endPoint: Api.login,
    }
}

export const postLoginRoot = (value) => {
    return {
        type: rootAction.Access.Login,
        requestOption: CreateRequestOption("post", value),
        endPoint: Api.Root.Login,
    }
}

export const authDetail = () => {
    return {
        type: rootAction.Access.Detail,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.authDetail
    }
}

export const register = () => {
    return {
        type: rootAction.Access.Register,
        requestOption: CreateRequestOption("post"),
        endPoint: Api.register
    }
}