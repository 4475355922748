import { ActionAffiliate } from "Actions";
import { Table } from "antd";
import { NotificationMessage } from "Constants/Utils";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { formatPrice } from "Helpers";

const WidgetListUser = (props) => {
    const [loading, setloading ] = useState(false);
    const [pagination, setpagination] = useState({
        page: 1,
        limit: 10
    })
    const User = props.Affiliate.user;

    useEffect(() => {
        getListData();
    }, [pagination])

    const getListData = async () => {
        setloading(true);
        try{
            let params = {...pagination};
            await props.getListData(params);
        } catch(err){
            NotificationMessage({
                type: "warning",
                response: err
            })
        }
        setloading(false);
    }

    return <React.Fragment>
        <div className="card shadow-none mb-3">
            <div className="card-header">
                <h5>Người dùng đăng ký: {props.Affiliate.affiliate.number_user} </h5>
            </div>
            <div className="card-body">
                <Table 
                    dataSource={User.listData}
                    columns={[
                        {
                            title: "Tên người dùng",
                            dataIndex: "fullname"
                        },
                        {
                            title: "Email",
                            dataIndex: "email"
                        },
                        {
                            title: "Thời gian nạp tiền mới nhất",
                            dataIndex: "transaction",
                            render: (value) => {
                                return value ? moment(value.last_time_payment).format("HH:mm DD/MM/YYYY") : "-"
                            }
                        },
                        {
                            title: "Số lần thanh toán",
                            dataIndex: "transaction",
                            render: (value) => {
                                return value ? value.count_payment : "-"
                            }
                        },
                        {
                            title: "Tổng tiền",
                            dataIndex: "transaction",
                            render: (value) => {
                                return value ? formatPrice(value.amount) : "-"
                            }
                        },
                        {
                            title: "Hoa hồng",
                            dataIndex: "transaction",
                            render: (value) => {
                                return value ? formatPrice(value.commission_amount) : "-"
                            }
                        },
                        {
                            title: "Ngày giới thiệu",
                            dataIndex: "created_time",
                            align: "right",
                            render: (value) => {
                                return moment(value).format("HH:mm DD-MM-YYYY")
                            }
                        }
                    ]}
                    pagination={{
                        total: User.total,
                        current: pagination.page,
                        pageSize: pagination.limit,
                        onChange: (page, pageSize) => {
                            setpagination({
                                page: page,
                                limit: pageSize
                            })
                        }
                    }}
                    loading={loading}
                />
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProp = (state) => ({
    Setting: state.Access.Setting,
    Affiliate: state.Affiliate
})

const mapDispatchToProps = (dispatch) => ({
    getListData: value => dispatch(ActionAffiliate.listUser(value))
})

export default connect(mapStateToProp, mapDispatchToProps)(WidgetListUser)