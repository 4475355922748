import React, { useRef, useState } from "react";
import { connect } from "react-redux";

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row } from 'antd';
import { UploadOutlined, InboxOutlined, VerticalAlignBottomOutlined, CheckOutlined, DownSquareOutlined, DownSquareFilled, CopyFilled } from '@ant-design/icons';

import moment from "moment";
import { ActionExportData } from "../Actions";

import XLSX from "xlsx";
import FileSaver from 'file-saver';

const Export = (props) => {
    const { visible, tableId, hide} = props;

    const [loading, setLoading] = useState(false);
    const from = useRef();

    const onExport = () => {
        let values = from.current.getFieldsValue();

        exportToCSV(values);
    }

    const exportToCSV = (options = {}) => {
        setLoading(true);

        let { file_extension, filename } = options;

        let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        let ws = XLSX.utils.table_to_sheet(document.getElementById(tableId), {
            raw: true,
            type: 'string'
        });

        let wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        let data = new Blob([excelBuffer], { type: fileType });
        setTimeout(() => {
            FileSaver.saveAs(data, filename + file_extension);
            setLoading(false);
        }, 1000)

    }

    return <Modal
        title="Export"
        visible={visible}
        onCancel={hide}
        footer={null}
    >
        <Form
            initialValues={{
                filename: moment().format("DD-MM-YYYY"),
                file_extension: ".xlsx"
            }}
            ref={from}
        >
            <Form.Item name="filename"
                rules={[
                    {
                        required: true
                    }
                ]}
            >
                <Input addonAfter=".xlsx"/>
            </Form.Item>
            <Form.Item name="file_extension" noStyle>
                <Input type="hidden"/>
            </Form.Item>
            <div className="export-container">
                
                <div className="export-header text-center d-flex align-items-center justify-content-center">
                    <Button type="primary" icon={<VerticalAlignBottomOutlined />} onClick={onExport} loading={loading}>
                        Tải xuống
                    </Button>
                    {/* <Button className="d-flex align-items-center ml-10" type="primary" icon={<CopyFilled />} >
                        Copy
                    </Button> */}
                </div>
            </div>
        </Form>
    </Modal>
}

const mapStateToProp = state => ({
    visible: state.ExportData.visible,
    tableId: state.ExportData.tableId
})

const mapDispatchToProps = dispatch => ({
    hide: value => dispatch(ActionExportData.hide())
})

export default connect(mapStateToProp, mapDispatchToProps)(Export);