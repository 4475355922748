import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, message, Image, Modal } from "antd";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import WidgetItemPackage from "./Widget/itemPackage";
import WidgetDetailTransaction from "./Widget/detailTransaction";
import WidgetListTransaction from "./Widget/listTransaction";
import { NotificationMessage } from "Constants/Utils";
import { ActionPackage } from "Actions";

const Package = (props) => {
  const { } = props;
  const [loading, setLoading] = useState(false);
  const [visibleDetailTransaction, setvisibleDetailTransaction] = useState({
    visible: false,
    item: null
  });


  useEffect(() => {

  }, [])

  const submitTransaction = (value) => {
    Modal.confirm({
      title: "Đăng ký gói cước",
      content: <div>Bạn có chắc muốn đăng ký "<b>{value.name}</b>"</div>,
      okText: "Xác nhận",
      cancelText: "Hủy bỏ",
      // centered: true,
      onOk: () => {
        return new Promise(async (resolve, reject) => {
          try {
            let response = await props.createPackage({
              package_id: value.id
            })
            setvisibleDetailTransaction({ visible: true, transaction: response.data.data })
            resolve();
          } catch (err) {
            NotificationMessage({ type: "warning", response: err });
            resolve();
          }
        })
      }
    })
  }

  //main return
  return (
    <React.Fragment>
      <div className="main-card card shadow-none" >
        <div className="card-header d-flex justify-content-between"><h5>Gói cước</h5></div>
        <div className="card-body">

          <WidgetDetailTransaction {...visibleDetailTransaction} onCancel={() => setvisibleDetailTransaction({ visible: false })} />

          {/* Pricing */}

          <div id="list-packages" className="row">
            {
              props.Setting.list_packages.map((item) => {
                return <WidgetItemPackage item={item}
                  key={item.id}
                  col={Math.floor(12 / props.Setting.list_packages.length)}
                  listFunction={props.Setting.app_tool.function}
                  onSubmit={(value) => submitTransaction(value)}
                />
              })
            }
          </div>

        </div>
      </div>
      <div className="main-card card shadow-none mt-3" >
        <div className="card-header d-flex justify-content-between"><h5>Giao dịch gần đây</h5></div>
        <div className="card-body">
            <WidgetListTransaction reload={visibleDetailTransaction.visible}/>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProp = (state) => ({
  Setting: state.Access.Setting
});

const mapDispatchToProps = (dispatch) => ({
  createPackage: value => dispatch(ActionPackage.create(value))
});

export default withRouter(
  connect(mapStateToProp, mapDispatchToProps)(Package)
);
