import rootAction from "../../../Actions/RootAction.json";
import { RedirectUrl, setCookie } from "../../../Constants/Utils";
import Router from "../../../Resources/Router.json";
import { AuthCookieName } from "../../../Constants/ConstantsValue";

const initialState = {
    UserLogin: null,
    Setting: {

    }
}

const access = (state = initialState, action) => {
    switch (action.type) {
        case rootAction.Access.Detail:
            if (!action.data.data) return initialState;
            return {
                ...state,
                UserLogin: action.data.data,
                Setting: action.data.service
            }

        case rootAction.Access.Logout:
            setCookie(AuthCookieName, "", 0);

            RedirectUrl(`${Router.Login}?${new URLSearchParams(action.params).toString()}`);
            return initialState;
        case rootAction.User.Update:
            if (!action.data.data) return initialState;
            return {
                ...state,
                UserLogin: {
                    ...state.UserLogin,
                    ...action.data.data
                }
            }
        default:
            break;
    }

    return state;
}

export default access;