import rootAction from "../../Actions/RootAction.json";

const initialState = {
    visible: false,
    tableId: ""
}

const ExportData = (state = initialState, action) => {
    switch (action.type) {
        case rootAction.ExportData.Open:
            return {
                ...state,
                ...action.data
            }
        case rootAction.ExportData.Hide:
            return {
                ...state,
                ...action.data
            }
        default:
            break;
    }
    return state;
}

export default ExportData;