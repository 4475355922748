import React, { Component, useState, useEffect } from 'react';
import { Form, Input, Spin, Button, Alert } from 'antd';
import { withRouter, Link } from 'react-router-dom';

import { useDispatch, useSelector, connect } from 'react-redux';

import { postLogin, postLoginRoot } from "../../Actions/Access";
import { RedirectWithComponent, getCookie, setCookie, IsAuthen } from "../../Constants/Utils";
import { AuthCookieName } from "../../Constants/ConstantsValue";

import rootRouter from "../../Resources/Router.json";

const LoginPage = (props) => {
    var form = null;
    const params = new URLSearchParams(props.location.search);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(params.get("message"));

    const submitLogin = async (values) => {
        setLoading(true);
        setTimeout(async () => {
            try {
                let resonse = await props.postLogin(values);
                console.log(resonse)
                setCookie(AuthCookieName, resonse.data.token, 7);
                if (params.get("url_redirect")) {
                    window.location.href = params.get("url_redirect");
                } else {
                    RedirectWithComponent("/");
                }
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        }, 600);
    }

    useEffect(() => {

    }, [])

    return <React.Fragment>
        <div className="modal-dialog w-100 mx-auto">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                            <div>Vui lòng đăng nhập vào tài khoản của bạn bên dưới.</div>
                            {/* <span>Please sign in to your account below.</span> */}
                        </h4>
                    </div>
                    <Form
                        onFinish={submitLogin}
                        ref={(evt) => form = evt}
                    >
                        {
                            error ? <Alert className="mb-4" type="error" message={error} /> : ""
                        }
                        <Form.Item name="username"
                            rules={[{ required: true }]}
                        >
                            <Input placeholder="Tài khoản..." className="rounded" size="large" />
                        </Form.Item>
                        <Form.Item name="password"
                            rules={[{ required: true }]}
                        >
                            <Input.Password placeholder="Mật khẩu..." size="large" className="rounded" />
                        </Form.Item>
                        <Button className="d-none" htmlType="submit" id="login"></Button>
                    </Form>
                    <div className="divider"></div>
                    <h6 className="mb-0">Bạn chưa có tài khoản? <Link to={rootRouter.Register} className="text-primary">Đăng ký</Link></h6>
                </div>
                <div className="modal-footer clearfix">
                    {/* <div className="float-left"><a href="#" className="btn-lg btn btn-link">Recover Password</a></div> */}
                    <div className="float-right">
                        <Button type="primary" size="large" className="btn-primary" onClick={(e) => {
                            e.preventDefault();
                            document.querySelector('#login').click();
                        }} loading={loading}>
                            Đăng nhập
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}


const mapStateToProp = state => ({
    UserLogin: state.Access.UserLogin,
    loading: state.ClientRequest.loading
});

const mapDispatchToProps = dispatch => ({
    postLogin: value => dispatch(postLoginRoot(value)),
    postLoginRoot: value => dispatch(postLogin(value)),
});


export default withRouter(connect(mapStateToProp, mapDispatchToProps)(LoginPage));