import rootAction from "./RootAction.json";

export const open = (values = {}) => {
    return {
        type: rootAction.ExportData.Open,
        data: {
            ...values,
            visible: true
        }
    }
}

export const hide = () => {
    return {
        type: rootAction.ExportData.Hide,
        data: {
            visible: false
        }
    }
}

export default {
    open,
    hide
}