import rootAction from "Actions/RootAction.json";

const initialState = {
    data: null
}

const Setting = (state = initialState, action) => {
    switch (action.type) {
        case rootAction.Admin.Setting.Get:
            if (!action.data) return initialState;
            return {
                ...state,
                data: action.data.data
            }
        default:
            break;
    }
    return state;
}

export default Setting;