import rootAction from "Actions//RootAction.json";
import { CreateRequestOption } from "Constants/Utils";
import { Api } from "Constants/ConstantsValue";

export const list = (params) => {
    return {
        type: rootAction.Admin.Affiliate.List,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Admin.Affiliate.List,
        params
    }
}

export const update = (value) => {
    return {
        type: rootAction.Admin.Affiliate.Update,
        requestOption: CreateRequestOption("put", value),
        endPoint: Api.Admin.Affiliate.Update
    }
}

export const listWithdraws = (params) => {
    return {
        type: rootAction.Admin.Affiliate.ListWithdraws,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Admin.Affiliate.ListWithdraws,
        params
    }
}

export const updateWithdraws = (id, value) => {
    return {
        type: rootAction.Admin.Affiliate.UpdateWithdraws,
        requestOption: CreateRequestOption("put", value),
        endPoint: Api.Admin.Affiliate.UpdateWithdraws+'/'+id
    }
}

export default {
    list,
    update,
    listWithdraws,
    updateWithdraws
}