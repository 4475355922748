import rootAction from "Actions/RootAction.json";

const initialState = {
    
}

const Package = (state = initialState, action) => {
    switch (action.type) {
        case rootAction.Notification.List:

            break
        default:
            break;
    }
    return state;
}

export default Package;