import rootAction from "Actions//RootAction.json";
import { CreateRequestOption } from "Constants/Utils";
import { Api } from "Constants/ConstantsValue";

export const list = (params) => {
    return {
        type: rootAction.Root.Tools.ListTools,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Root.ToolList,
        params
    }
}

export const postList = (value) => {
    return {
        type: rootAction.Root.Tools.UpdateTools,
        requestOption: CreateRequestOption("post", value),
        endPoint: Api.Root.ToolPost,
    }
}

export const listDomain = (params) => {
    return {
        type: rootAction.Root.Domain.ListDomain,
        requestOption: CreateRequestOption("get"),
        endPoint: Api.Root.ListDomain,
        params
    }
}

export const postListDomain = (value) => {
    return {
        type: rootAction.Root.Domain.PostDomain,
        requestOption: CreateRequestOption("post", value),
        endPoint: Api.Root.PostDomain,
    }
}

export default {
    list,
    postList,
    listDomain,
    postListDomain
}