import { combineReducers } from "redux";

import Access from "./Components/Common/Access";
import ThemeOptions from "./ThemeOption";
import ClientRequest from "./ClientRequest";
import Notification from "./Pages/Notification";
import ExportData from "./Components/ExportData";
import Transaction from "./Pages/Transaction";
import Package from "./Pages/Package";
import Config from "./Config";
import Affiliate from "./Pages/Affiliate";

import AdminTransaction from "./Pages/Admin/Transaction";
import AdminUser from "./Pages/Admin/User";
import AdminSetting from "./Pages/Admin/Setting";
import AdminAffiliate from "./Pages/Admin/Affiliate";

import RootGetList from "./Pages/Root/GetList";
import RootDomain from "./Pages/Root/Domain";

const rootReducer = combineReducers({
  Access: Access,
  ThemeOptions: ThemeOptions,
  ClientRequest: ClientRequest,
  Notification,
  ExportData,
  Transaction,
  Package,
  Config,
  AdminTransaction,
  AdminUser,
  AdminSetting,
  Affiliate,
  AdminAffiliate,
  RootGetList,
  RootDomain
});

export default rootReducer;
